/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover } from 'antd'
import { useState } from 'react'
import { deleteFlat } from '../api'
import FlatsAddDrawer from './FlatsAddDrawer'

function FlatsTableRow({ flat, refetch }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDeleteFlat, isLoading: isLoadingDeletingFlat } =
    useMutation({
      mutationFn: deleteFlat,
      onSuccess: () => {
        showMessage('success', 'Кредит успешно удален.')
        refetch()
        setOpen(false)
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении контакта.')
      },
    })

  return (
    <>
      <FlatsAddDrawer
        setAddDrawerOpen={setAddDrawerOpen}
        addDrawerOpen={addDrawerOpen}
        refetch={refetch}
        flat={flat}
      />

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setOpen(false)
                setAddDrawerOpen(true)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-between"
            >
              Изменить
            </Button>
            <Button
              type="link"
              onClick={() => {
                mutateDeleteFlat(flat?.id)
              }}
              loading={isLoadingDeletingFlat}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={(val) => {
          setOpen(val)
        }}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:bg-[#272B30] dark:border-[#272B30]">
          <MoreOutlined />
        </Button>
      </Popover>
    </>
  )
}

export default FlatsTableRow
