import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { getApartmentTypes } from '@/features/clients/api'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import getListBlocks from '../api'

function FlatsFilterDrawer({
  setFilterDrawerOpen,
  filterDrawerOpen,
  control,
  reset,
  unregister,
}: any): JSX.Element {
  const { data: apartmenTypeList } = useQuery({
    queryKey: ['getApartmentTypes'],
    queryFn: getApartmentTypes,
  })

  const { data: blockList } = useQuery({
    queryKey: ['blocks'],
    queryFn: (): any => getListBlocks({}),
  })

  return (
    <Drawer
      onClose={() => {
        setFilterDrawerOpen(false)
      }}
      open={filterDrawerOpen}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 dark:border-[#272B30] border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFF]">
              Фильтр
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setFilterDrawerOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] dark:bg-[#272B30] dark:text-[#A9A9A9]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Тип планировки">
              <HFSelect
                control={control}
                name="filters.apartment_type"
                // options={apartmenTypeList?.map((item: any) => ({
                //   label: item.title,
                //   value: item.id,
                // }))}
                options={[
                  {
                    label: 'Квартира',
                    value: 1,
                  },
                  {
                    label: 'Студия',
                    value: 2,
                  },
                ]}
              />
            </FRow>

            <FRow label="Количество комнат">
              <HFSelect
                control={control}
                name="filters.room_count"
                options={apartmenTypeList?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Номер дома">
              <HFSelect
                required
                control={control}
                name="filters.block_id"
                options={blockList?.results?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Общая площадь м2">
              <HFInput
                control={control}
                name="filters.total_space"
                // options={apartmenTypeList?.map((item: any) => ({
                //   label: item.title,
                //   value: item.id,
                // }))}
              />
            </FRow>

            <FRow label="Цена за м2">
              <Flex gap={16}>
                <HFInput
                  control={control}
                  suffix="uzs"
                  prefix="от"
                  name="filters.price_from"
                  type="number"
                />

                <HFInput
                  control={control}
                  suffix="uzs"
                  prefix="до"
                  name="filters.price_to"
                  type="number"
                />
              </Flex>
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={22} className="w-full p-[24px]">
          <Button
            onClick={() => {
              setFilterDrawerOpen(false)
              reset()
              unregister()
            }}
            className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#1E1E1E] font-normal dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
          >
            Сбросить
          </Button>

          <Button
            onClick={() => {
              setFilterDrawerOpen(false)
            }}
            className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default FlatsFilterDrawer
