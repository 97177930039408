import useMessage from '@/hooks/useShowMessage'
import { HomeOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { getRCPage } from '../api'
import RCPageEdit from '../components/RCPageEdit'
import RCPageForm from '../components/RCPageForm'

function RCPage(): JSX.Element {
  const [activeTab, setIsActiveTab] = useState('#details')

  const highlightItems = [
    {
      title: 'Детальная информация',
      href: '#details',
      height: 620,
    },
    {
      title: 'Расположение ЖК',
      href: '#location',
      height: 720,
    },
    {
      title: 'Характеристики',
      href: '#characteristics',
      height: 820,
    },
    {
      title: 'Галерея',
      href: '#gallery',
      height: 360,
    },
    {
      title: 'Преимущества ЖК',
      href: '#advantages',
      height: 840,
    },
  ]

  const [scrollPosition, setScrollPosition] = useState(0)
  const showMessage = useMessage()
  const handleScroll = (): void => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition < 770 && scrollPosition > 140) {
      setIsActiveTab('#details')
    } else if (scrollPosition > 770 && scrollPosition < 1500) {
      setIsActiveTab('#location')
    } else if (scrollPosition > 1500 && scrollPosition < 2300) {
      setIsActiveTab('#characteristics')
    } else if (scrollPosition > 2300 && scrollPosition < 2800) {
      setIsActiveTab('#gallery')
    } else if (scrollPosition > 2800) {
      setIsActiveTab('#advantages')
    }
  }, [scrollPosition])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['rc-page-get-list'],
    cacheTime: 0,
    queryFn: getRCPage,
    config: {
      refetchOnWindowFocus: false,
    },
    onError: (error) => {
      showMessage('error', 'Вы еще не создали ЖК')
    },
  })

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        separator={
          <span className="text-[#A9A9A9] dark:text-[#A9A9A9]">/</span>
        }
        items={[
          {
            href: '/',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span>Главная</span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span>Жилой комплекс</span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#FFF]">
                <span>Страница </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex className="mt-[32px] grid grid-cols-[4fr_1fr] gap-[24px] bg-[#FCFCFD] dark:bg-[#111315] relative">
        {isLoading && (
          <Flex className="w-full h-full absolute items-center justify-center bg-[#ffffffd7] z-10">
            <Spin size="large" spinning={isLoading} />
          </Flex>
        )}

        {data?.id ? (
          <RCPageEdit data={data} refetch={refetch} />
        ) : (
          <RCPageForm data={data} />
        )}

        <Flex className="h-full w-full">
          <Flex
            vertical
            gap={16}
            className="p-[16px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max w-full sticky top-[100px] dark:bg-[#1A1D1F] dark:border-[#1A1D1F]"
          >
            {highlightItems.map((item) => (
              <Button
                key={item.title}
                href={item.href}
                style={{
                  backgroundColor: activeTab === item.href ? '#F1F2F4' : '',
                }}
                className="border-none flex justify-start items-center p-[12px] text-[#1E1E1E] hover:!text-[#1E1E1E] rounded-[12px] text-base font-normal h-auto shadow-none text-start hover:!bg-[#F1F2F4] dark:bg-[#272B30] hover:dark:!bg-[#272B30] dark:text-[#FFF] dark:hover:!text-[#FFF]"
              >
                {item.title}
              </Button>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RCPage
