import BlurImage from '@/components/BlurImage'
import { Flex, Modal } from 'antd'
import { useState } from 'react'

export default function FlatPlanImage({ client }: any): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Flex>
      <BlurImage
        src={client?.image}
        preview={false}
        width={68}
        height={68}
        onClick={() => {
          setIsModalOpen(true)
        }}
      />

      <Modal
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false)
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        footer={null}
        width={800}
      >
        <Flex className="w-full">
          <BlurImage src={client?.image} preview={false} width="100%" />
        </Flex>
      </Modal>
    </Flex>
  )
}
