import ArrowRightIcon from '@/components/icons/arrow-right'
import StarFilledIcon from '@/components/icons/star-icon'
import StarsIcon from '@/components/icons/stars-icon'
import { Button, Divider, Flex, Progress, Skeleton, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function AnalyticsRate({
  overAllRating,
  allRatesCount,
  statisticsReview,
  isLoading,
}: any): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  const bgGeneratol = (rate: number, number: number): string => {
    if (rate > number) {
      return '#FA8F54'
    }
    return isDarkMode ? '#272B30' : '#E5E7EB'
  }

  return (
    <Flex className="bg-[#FFF] h-full rounded-[12px] border border-[#E5E7EB] border-solid overflow-hidden dark:bg-[#1A1D1F] dark:border-[#272B30] div6">
      <Flex vertical className="p-[24px] w-full">
        <Typography.Title
          level={5}
          className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 w-full dark:!text-[#FFF]"
        >
          Отзывы
        </Typography.Title>

        <Divider className="mt-[12px] mb-[8px] dark:bg-[#272B30]" />

        <Flex vertical>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Flex vertical>
              <Flex gap={8} className="mb-[16px] items-center">
                <StarFilledIcon
                  width="44"
                  height="44"
                  style={{ color: '#FA8F54' }}
                />
                <Typography.Text className="!text-[#1E1E1E] !text-6xl !font-medium dark:!text-[#FFF]">
                  {isNaN(overAllRating) ? 0 : overAllRating}
                </Typography.Text>
              </Flex>

              <Flex gap={8} className="items-center">
                <Typography.Text className="!text-[#878787] !text-base !font-normal text-nowrap">
                  -из {allRatesCount} отзывов
                </Typography.Text>
              </Flex>
            </Flex>
          )}

          <Divider className="my-[37px] dark:bg-[#272B30]" />

          <Flex vertical gap={24}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              statisticsReview.map((item: any) => (
                <Flex key={item.rate} gap={16} className="items-center w-full">
                  <Flex gap={2}>
                    <StarFilledIcon
                      width="20"
                      height="20"
                      style={{
                        color: bgGeneratol(item.rate, 0),
                      }}
                    />
                    <StarFilledIcon
                      width="20"
                      height="20"
                      style={{
                        color: bgGeneratol(item.rate, 1),
                      }}
                    />
                    <StarFilledIcon
                      width="20"
                      height="20"
                      style={{
                        color: bgGeneratol(item.rate, 2),
                      }}
                    />
                    <StarFilledIcon
                      width="20"
                      height="20"
                      style={{
                        color: bgGeneratol(item.rate, 3),
                      }}
                    />
                    <StarFilledIcon
                      width="20"
                      height="20"
                      style={{
                        color: bgGeneratol(item.rate, 4),
                      }}
                    />
                  </Flex>

                  <Flex className="w-full">
                    <Progress
                      percent={(item.count / 6) * 100}
                      status="active"
                      trailColor={isDarkMode ? '#272B30' : '#E5E7EB'}
                      strokeColor="#FA8F54"
                      showInfo={false}
                    />
                  </Flex>

                  <Flex className="items-center">
                    <Typography.Text className="!text-[#878787] !text-sm !font-normal">
                      {item.count}
                    </Typography.Text>
                  </Flex>
                </Flex>
              ))
            )}
          </Flex>

          <Flex className="mt-[32px]">
            <Button
              type="link"
              onClick={() => {
                navigate('/reviews')
              }}
              className="flex items-center justify-between w-full p-0 text-[#1E1E1E] text-base font-normal cursor-pointer dark:text-[#FFF]"
            >
              <Typography.Text className="rounded-[8px] border border-[#E5E7EB] border-solid w-[38px] h-[38px] !flex items-center justify-center dark:bg-[#111315] dark:border-[#1A1D1F]">
                <StarsIcon />
              </Typography.Text>
              Показать все отзывы
              <ArrowRightIcon />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AnalyticsRate
