import { Button, Flex, Typography } from 'antd'
import { useState } from 'react'

export default function RatingCommentRow({ item }: any): JSX.Element {
  const [isShowedCommentMore, setIsShowedCommentMore] = useState(false)

  return (
    <Flex vertical gap={12} className="rounded-none">
      <Typography.Text
        style={{
          display: '-webkit-box',
          width: '100%',
          height: isShowedCommentMore ? 'auto' : '32px',
          overflow: 'hidden',
          padding: '0',
        }}
        className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] h-max w-max flex items-center gap-[2px]"
      >
        {item?.comment}
      </Typography.Text>

      {item?.comment.length > 40 && (
        <Button
          type="link"
          className="text-[#1E1E1E] w-max font-normal text-xs rounded-[4px] h-auto flex items-center justify-start border border-solid border-[#E5E7EB] dark:border-[#272B30] p-[4px]"
          onClick={() => {
            setIsShowedCommentMore(!isShowedCommentMore)
          }}
        >
          {isShowedCommentMore ? 'Скрыть' : 'Показать полностью'}
        </Button>
      )}
    </Flex>
  )
}
