/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable no-return-await */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import HFTextarea from '@/components/FormElements/HFTextarea'
import { useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Image, Typography } from 'antd'
import { getListInstallment, getListIpoteka } from '../../api'
import GalleryImage from '../../assets/gallery.svg'

function MainInformationSection({
  control,
  watch,
  setValue,
}: any): JSX.Element {
  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[id="main"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[id="main"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]

        if (file) {
          setValue(`image`, file)
        }
      })
  }

  const { data: ipotekaList, isLoading: ipotekaLoader } = useQuery({
    queryKey: ['getListIpoteka'],
    queryFn: async () => await getListIpoteka(),
  })

  const { data: installmentList, isLoading: installmentLodaer } = useQuery({
    queryKey: ['getListInstallment'],
    queryFn: async () => await getListInstallment(),
  })

  return (
    <section id="details">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#272B30]">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !m-0 !text-[32px] dark:!text-[#FFF]">
            Страница ЖК
          </Typography.Title>
        </Flex>

        <Flex vertical className="pt-[24px]">
          <Typography.Text className="!text-[#1E1E1E] !font-medium !text-2xl mb-[24px] dark:!text-[#FFF]">
            Детальная информация
          </Typography.Text>
          <input
            hidden
            id="main"
            type="file"
            accept="image/jpeg, image/png, image/jpg, image/webp"
          />

          <Flex vertical gap={24}>
            <FRow label="Добавить фотографи ЖК">
              <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] overflow-hidden dark:border-[#2F3336]">
                {watch(`image`) ? (
                  <Flex className="relative items-center justify-center min-h-[200px] h-[200px] w-full">
                    <Image
                      width="100%"
                      height="100%"
                      className="object-cover"
                      src={
                        isValidURL(watch(`image`))
                          ? watch(`image`)
                          : URL.createObjectURL(watch(`image`))
                      }
                      preview
                    />

                    <Flex
                      className="absolute right-1 top-1 items-center"
                      gap={12}
                    >
                      <Button
                        onClick={inputClick}
                        className="!bg-[#635BFF] !text-white !font-medium rounded-[8px] !text-base"
                      >
                        Изменить
                      </Button>
                      <Button
                        onClick={() => {
                          setValue(`image`, null)
                        }}
                        className="!bg-[#FF4D4F] !text-white !font-medium rounded-[8px] !text-base"
                      >
                        Удалить
                      </Button>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    onClick={inputClick}
                    className="items-center justify-center min-h-[200px] w-full"
                  >
                    <Image width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px] dark:!text-[#FFF]">
                      Выберите или перетащите минимум 1 фото
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                      Максимальный размер: 5 МБ.
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            <FRow label="Название жилого комплекса">
              <HFInput
                control={control}
                required
                name="name"
                placeholder="Например: Хонсарой"
              />
            </FRow>

            <Flex className="grid grid-cols-2 mb-[24px]" gap={24}>
              <Flex vertical gap={10}>
                <Flex className="w-full items-center justify-between">
                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    О жилом комплексе
                  </Typography.Text>

                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    РУ
                  </Typography.Text>
                </Flex>

                <HFTextarea
                  required
                  control={control}
                  name="description_ru"
                  placeholder="О жилом комплексе"
                  rows={6}
                />
              </Flex>

              <Flex vertical gap={10}>
                <Flex className="w-full items-center justify-between">
                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    О жилом комплексе
                  </Typography.Text>

                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    O’Z
                  </Typography.Text>
                </Flex>

                <HFTextarea
                  required
                  control={control}
                  name="description_uz"
                  placeholder="О жилом комплексе"
                  rows={6}
                />
              </Flex>

              <Flex vertical gap={10}>
                <Flex className="w-full items-center justify-between">
                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    О жилом комплексе
                  </Typography.Text>

                  <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                    ЎЗ
                  </Typography.Text>
                </Flex>

                <HFTextarea
                  required
                  control={control}
                  name="description_cyrill"
                  placeholder="О жилом комплексе"
                  rows={6}
                />
              </Flex>
            </Flex>

            <Flex>
              <FRow label="Выберите теги">
                <Flex className="grid grid-cols-2" gap={24}>
                  <Flex vertical gap={12}>
                    <Checkbox
                      checked={watch('installment_boolean')}
                      onChange={(e) => {
                        setValue(`installment_boolean`, e.target.checked)
                        if (!e.target.checked) {
                          setValue(`installment`, null)
                        }
                      }}
                    >
                      Рассрочка
                    </Checkbox>

                    <FRow label="Максимальное колво месяцев">
                      <HFSelect
                        control={control}
                        loading={installmentLodaer}
                        disabled={!watch('installment_boolean')}
                        name="installment"
                        options={installmentList?.map((item: any) => ({
                          label: `${item?.month} месяцев`,
                          value: item?.id,
                        }))}
                      />
                    </FRow>
                  </Flex>

                  <Flex vertical gap={12}>
                    <Checkbox
                      checked={watch('ipoteka_boolean')}
                      onChange={(e) => {
                        setValue(`ipoteka_boolean`, e.target.checked)
                        if (!e.target.checked) {
                          setValue(`ipoteka`, null)
                        }
                      }}
                    >
                      Ипотека
                    </Checkbox>

                    <FRow label="Процент ипотеки">
                      <HFSelect
                        loading={ipotekaLoader}
                        control={control}
                        disabled={!watch('ipoteka_boolean')}
                        name="ipoteka"
                        options={ipotekaList?.map((item: any) => ({
                          label: `${item?.percent} %`,
                          value: item?.id,
                        }))}
                      />
                    </FRow>
                  </Flex>
                </Flex>
              </FRow>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </section>
  )
}

export default MainInformationSection
