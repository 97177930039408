/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-floating-promises */
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover } from 'antd'
import { useState } from 'react'
import { changeStatusReview } from '../api'

function RatingTableRow({ item, refetch }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }
  const showMessage = useMessage()

  const { mutate: mutateUpdateReview, isLoading: loadingUpdate } = useMutation({
    mutationFn: () => {
      return changeStatusReview(
        item?.id,
        item?.status === 'active' ? 'inactive' : 'active',
      )
    },
    onSuccess: async () => {
      await refetch()
    },
    onError: () => {
      showMessage('error', 'Ошибка при обновлении отзыва.')
    },
  })

  const { mutate: mutateDeleteReview, isLoading: loadingDelete } = useMutation({
    mutationFn: () => {
      return changeStatusReview(item?.id, 'deleted')
    },
    onSuccess: async () => {
      await refetch()
    },
    onError: () => {
      showMessage('error', 'Ошибка при удаления отзыва.')
    },
  })

  return (
    <Popover
      content={
        <Flex vertical className="min-w-[170px]">
          <Button
            type="link"
            loading={loadingUpdate}
            onClick={() => {
              mutateUpdateReview()
            }}
            className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
          >
            {item?.status === 'active' ? 'Скрыть' : 'Активировать'}
          </Button>
          <Button
            type="link"
            loading={loadingDelete}
            onClick={() => {
              mutateDeleteReview()
            }}
            className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
          >
            Удалить
          </Button>
        </Flex>
      }
      placement="bottomRight"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:!bg-[#272B30] dark:border-[#272B30]">
        <MoreOutlined />
      </Button>
    </Popover>
  )
}

export default RatingTableRow
