/* eslint-disable @typescript-eslint/no-floating-promises */
import DeleteModal from '@/components/delete-modal'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { deleteContact, updateContact } from '../api'
import ContactFormDrawer from './ContactFormDrawer'

function TableRow({ item }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const showMessage = useMessage()
  const { control, getValues, setValue, reset, handleSubmit, watch } = useForm()
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const [selectedRow, setSelectedRow] = useState<any>(null)

  const { mutate: mutateUpdateContact, isLoading: isLoadingUpdating } =
    useMutation({
      mutationFn: updateContact,
      onSuccess: () => {
        setDrawerOpen(false)
        showMessage('success', 'Контакт успешно обновлен.')
        queryClient.invalidateQueries(['getListContacts'])
      },
      onError: () => {
        showMessage(
          'error',
          'Ошибка при обновлении контакта. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const { mutate: mutateDeleteContact, isLoading: isLoadingDeleting } =
    useMutation({
      mutationFn: deleteContact,
      onSuccess: () => {
        setDrawerOpen(false)
        showMessage('success', 'Контакт успешно удален.')
        queryClient.invalidateQueries(['getListContacts'])
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении контакта.')
      },
    })

  return (
    <>
      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              onClick={() => {
                setDrawerOpen(true)
                setOpen(false)
                setSelectedRow(item)
              }}
              loading={isLoadingUpdating}
              type="link"
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDeleteApproveModal(true)
                handleOpenChange(false)
              }}
              loading={isLoadingDeleting}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Flex className="w-full justify-end">
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:border-[#272B30] dark:!bg-[#272B30]">
            <MoreOutlined />
          </Button>
        </Flex>
      </Popover>

      <ContactFormDrawer
        getValues={getValues}
        control={control}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        mutateCreateContact={mutateUpdateContact}
        isLoadingCreating={isLoadingUpdating}
        type={selectedRow?.id}
        setValue={setValue}
        reset={reset}
        handleSubmit={handleSubmit}
      />

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          mutateDeleteContact(item.id)
          setOpen(false)
        }}
        isLoadingDeleting={isLoadingDeleting}
      />
    </>
  )
}

export default TableRow
