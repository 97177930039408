/* eslint-disable @typescript-eslint/no-floating-promises */
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover } from 'antd'
import { useState } from 'react'
import { updateClient } from '../api'

function TableRow({ client, refetchList }: any): JSX.Element {
  const [open, setOpen] = useState(false)

  const showMessage = useMessage()

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { mutate: mutateUpdateClient1, isLoading: isLoadingUpdate1 } =
    useMutation({
      mutationFn: updateClient,
      onSuccess: () => {
        showMessage('success', 'Клиент успешно обновлен.')
        handleOpenChange(false)
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
      },
      onError: () => {
        showMessage('error', 'Ошибка при обновлении клиента.')
      },
    })

  const { mutate: mutateUpdateClient2, isLoading: isLoadingUpdate2 } =
    useMutation({
      mutationFn: updateClient,
      onSuccess: () => {
        showMessage('success', 'Клиент успешно обновлен.')
        handleOpenChange(false)
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
      },
      onError: () => {
        showMessage('error', 'Ошибка при обновлении клиента.')
      },
    })

  return (
    <Popover
      content={
        <Flex vertical className="min-w-[170px]">
          {client?.status !== 2 && (
            <Button
              type="link"
              loading={isLoadingUpdate1}
              onClick={() => {
                mutateUpdateClient1({
                  ...client,
                  id: client?.id,
                  status: 2,
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Обработано
            </Button>
          )}
          {client?.status !== 4 && (
            <Button
              type="link"
              loading={isLoadingUpdate2}
              onClick={() => {
                mutateUpdateClient2({
                  ...client,
                  id: client?.id,
                  status: 4,
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Архивировать
            </Button>
          )}
        </Flex>
      }
      placement="bottomRight"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:border-[#272B30] dark:!bg-[#272B30]">
        <MoreOutlined />
      </Button>
    </Popover>
  )
}

export default TableRow
