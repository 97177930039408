/* eslint-disable @typescript-eslint/no-floating-promises */
import DeleteModal from '@/components/delete-modal'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteBlock, updateBlock } from '../api'
import HouseAddDrawer from './HouseAddDrawer'

function HouseTableRow({ house, refetch }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)
  const showMessage = useMessage()

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { mutate: mutateUpdateCredit, isLoading: isLoadingUpdateCredit } =
    useMutation({
      mutationFn: updateBlock,
      onSuccess: async (res) => {
        await refetch()
        setAddDrawerOpen(false)
        showMessage('success', 'Дом успешно обновлен.')
      },
      onError: async () => {
        showMessage(
          'error',
          'Ошибка при обновление дома. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const { mutate: mutateDeleteCredit, isLoading: isLoadingDeleting } =
    useMutation({
      mutationFn: deleteBlock,
      onSuccess: async () => {
        showMessage('success', 'Дом успешно удален.')
        await refetch()
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении дома.')
      },
    })

  return (
    <>
      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setAddDrawerOpen(true)
                handleOpenChange(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            <Button
              type="link"
              loading={isLoadingUpdateCredit}
              onClick={() => {
                mutateUpdateCredit({
                  ...house,
                  status: house?.status === 'active' ? 'hidden' : 'active',
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              {house?.status === 'active' ? 'Скрыть' : 'Активировать'}
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDeleteApproveModal(true)
                handleOpenChange(false)
              }}
              loading={isLoadingDeleting}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button className="border-[#E5E7EB] dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center  dark:bg-[#1A1D1F] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF]">
          <MoreOutlined />
        </Button>
      </Popover>

      <HouseAddDrawer
        setAddDrawerOpen={setAddDrawerOpen}
        addDrawerOpen={addDrawerOpen}
        refetch={refetch}
        house={house}
      />

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          mutateDeleteCredit(house.id)
          handleOpenChange(false)
        }}
      />
    </>
  )
}

export default HouseTableRow
