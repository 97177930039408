/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-return-await */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import CarMetroIcon from '@/components/icons/car-icon'
import WalkingMetroIcon from '@/components/icons/walking-to-metro'
import {
  getListLandmarks,
  getListMetros,
  getListRegions,
  updateRCPage,
} from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Divider, Flex, Modal, Typography } from 'antd'
import axios from 'axios'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'

function InfrastructureModal({
  setInfrastructureModal,
  infrastructureModal,
  refetch,
  data,
  showMessage,
}: any): JSX.Element {
  const { control, setValue, watch, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      region: undefined,
      district: undefined,
      latitude: '',
      longitude: '',
      nearby_metros: [
        {
          metro: undefined,
          type: 1,
          time: 5,
        },
      ],
      landmarks: [],
      address_uz: '',
      address_ru: '',
      address_cyrill: '',
    },
  })
  console.log('ewatch', watch('region'))

  useEffect(() => {
    if (infrastructureModal && data) {
      reset({
        region: data?.region?.id ?? undefined, // Ensure `region` is reset to the correct ID
        district: data?.district?.id ?? undefined,
        latitude: data?.latitude ?? '',
        longitude: data?.longitude ?? '',
        nearby_metros: data?.nearby_metros ?? [
          {
            metro: undefined,
          },
        ],
        landmarks: data?.landmarks.map((item: any) => item.id) ?? [],
        address_uz: data?.address_uz ?? '',
        address_ru: data?.address_ru ?? '',
        address_cyrill: data?.address_cyrill ?? '',
      })
    }
  }, [infrastructureModal, data, reset])

  const { data: regions } = useQuery({
    queryKey: ['regions'],
    queryFn: getListRegions,
  })

  const { data: metros } = useQuery({
    queryKey: ['metros'],
    queryFn: getListMetros,
  })

  const { data: landmarks } = useQuery({
    queryKey: ['landmarks'],
    queryFn: getListLandmarks,
  })

  const getNominalByCoords = async (
    latitude: number,
    longitude: number,
    lang: string,
  ): any => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`

    try {
      const response = await axios.get(url, {
        headers: {
          'Accept-Language': lang,
        },
      })
      const { data: dataField } = response

      if (dataField.display_name) {
        setValue(`address_${lang}`, dataField.display_name)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickMap = (e: any): void => {
    const coords = e.get('coords')
    getNominalByCoords(coords[0], coords[1], 'ru')
    getNominalByCoords(coords[0], coords[1], 'uz')

    setValue('latitude', coords[0])
    setValue('longitude', coords[1])
  }

  const longitude = watch('longitude')
  const latitude = watch('latitude')
  const isCoordsValid = longitude !== undefined && latitude !== undefined

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () =>
      await updateRCPage(
        {
          ...getValues(),
          nearby_metros:
            getValues('nearby_metros')?.[0]?.metro === '' ||
            getValues('nearby_metros')?.[0]?.metro === undefined
              ? []
              : getValues('nearby_metros'),
        },
        data?.id,
      ),
    onSuccess: () => {
      setInfrastructureModal(false)
      showMessage('success', 'Инфраструктура успешно изменена')
      refetch()
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const updateTitle = (): void => {
    mutateUpdate()
  }

  return (
    <Modal
      open={infrastructureModal}
      onOk={() => setInfrastructureModal(false)}
      onCancel={() => setInfrastructureModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc h-[80vh] overflow-scroll rounded-[16px]"
      width="80%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Расположение и инфраструктура
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setInfrastructureModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex
        vertical
        className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid"
      >
        <Flex vertical gap={24}>
          <Flex gap={16}>
            <FRow label="Регион">
              <HFSelect
                control={control}
                required
                allowClear
                name="region"
                options={regions?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Район">
              <HFSelect
                control={control}
                required
                name="district"
                options={regions
                  ?.find((item: any) => item.id === watch('region'))
                  ?.districts.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
              />
            </FRow>
          </Flex>

          <Flex gap={16}>
            <FRow label="Адрес (РУ)">
              <HFInput control={control} required name="address_ru" />
            </FRow>

            <FRow label="Адрес (O’Z)">
              <HFInput control={control} required name="address_uz" />
            </FRow>

            <FRow label="Адрес (ЎЗ)">
              <HFInput control={control} required name="address_cyrill" />
            </FRow>
          </Flex>

          <Flex className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid h-[500px]">
            <YMaps>
              <Map
                onClick={handleClickMap}
                width="100%"
                height="500px"
                defaultState={{
                  center: [watch('latitude'), watch('longitude')],
                  zoom: 12,
                }}
              >
                <ZoomControl />
                {isCoordsValid && (
                  <Placemark
                    geometry={[watch('latitude'), watch('longitude')]}
                  />
                )}
              </Map>
            </YMaps>
          </Flex>

          {watch('region') === 3 && (
            <Flex gap={24}>
              <FRow label="Ближайшее метро">
                <HFSelect
                  control={control}
                  key={watch('nearby_metros.0.metro')}
                  allowClear
                  name="nearby_metros.0.metro"
                  placeholder="Выберите метро"
                  className="!h-full"
                  options={metros?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </FRow>

              <Flex vertical>
                <Flex className="text-[#1E1E1E] text-sm font-medium mb-[10px] dark:!text-[#FFF]">
                  Расстояние до метро
                </Flex>
                <Flex className="p-[3px] bg-[#F1F2F4] rounded-[8px] w-max h-[40px]">
                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.type') === 1
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metros.0.type', 1)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <WalkingMetroIcon />
                  </Button>

                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.type') === 2
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metros.0.type', 2)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <CarMetroIcon />
                  </Button>
                </Flex>
              </Flex>

              <Flex vertical>
                <Flex className="h-[24px] text-[#1E1E1E] text-sm font-medium ">
                  Время до метро
                </Flex>
                <Flex className="w-full" gap={12}>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 5)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 5
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    5 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 10)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 10
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    10 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 15)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 15
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    15 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 20)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 20
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    20 мин.
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Divider className="m-0" />

          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                По ориентиру
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {landmarks?.length > 0 ? (
                <Checkbox.Group
                  options={landmarks?.map((item: any) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  className="w-full grid grid-cols-4 gap-[16px] text-[#1E1E1E]"
                  value={watch('landmarks')}
                  onChange={(values) => {
                    setValue('landmarks', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет доступных ориентиров
                </Typography.Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setInfrastructureModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTitle)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default InfrastructureModal
