/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  getListExteriorFeatures,
  getListFacilities,
  getListInteriorFeatures,
  getListSafetySystem,
  updateRCPage,
} from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Divider, Flex, Modal, Typography } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

function ConvenienceModal({
  setConvenienceModal,
  convenienceModal,
  refetch,
  data,
  showMessage,
}: any): JSX.Element {
  const { setValue, watch, handleSubmit, getValues } = useForm()

  useEffect(() => {
    setValue(
      'interior_features',
      data?.interior_features?.map((item: any) => item?.id) ?? [],
    )
    setValue(
      'safety_systems',
      data?.safety_systems?.map((item: any) => item?.id) ?? [],
    )
    setValue(
      'exterior_features',
      data?.exterior_features?.map((item: any) => item?.id) ?? [],
    )
  }, [data])

  // const { data: listInteriorFeatures } = useQuery({
  //   queryKey: ['listInteriorFeatures'],
  //   queryFn: getListInteriorFeatures,
  // })

  // const { data: listSafetySystem } = useQuery({
  //   queryKey: ['listSafetySystem'],
  //   queryFn: getListSafetySystem,
  // })

  // const { data: listExteriorFeatures } = useQuery({
  //   queryKey: ['listExteriorFeatures'],
  //   queryFn: getListExteriorFeatures,
  // })

  const { data: buildingFacilities } = useQuery({
    queryKey: ['buildingFacilities'],
    queryFn: async () =>
      await getListFacilities({
        page_size: 100,
        goal_type: 1,
        property_types__alias: 'apartment',
      }),
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () => await updateRCPage(getValues(), data?.id),
    onSuccess: () => {
      setConvenienceModal(false)
      showMessage('success', 'Удобства успешно изменены')
      refetch()
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const updateTitle = (): void => {
    mutateUpdate()
  }

  return (
    <Modal
      open={convenienceModal}
      onOk={() => setConvenienceModal(false)}
      onCancel={() => setConvenienceModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="80%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Удобство ЖК
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setConvenienceModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex
        vertical
        className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid"
      >
        <Flex vertical gap={24}>
          <Flex vertical gap={24}>
            {/* <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Особенности интерьера
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex> */}

            <Flex>
              {buildingFacilities?.results?.length > 0 ? (
                <Checkbox.Group
                  options={
                    buildingFacilities?.results?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('facilities')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('facilities', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          {/* <Divider className="m-0" /> */}

          {/* <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Система безопасности
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {listSafetySystem?.length > 0 ? (
                <Checkbox.Group
                  options={
                    listSafetySystem?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('safety_systems')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('safety_systems', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          <Divider className="m-0" />

          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Особенности экстерьера
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {listExteriorFeatures?.length > 0 ? (
                <Checkbox.Group
                  options={
                    listExteriorFeatures?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('exterior_features')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('exterior_features', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex> */}
        </Flex>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setConvenienceModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTitle)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default ConvenienceModal
