/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import BlurImage from '@/components/BlurImage'
import DislikeIcon from '@/components/icons/dislike-icon'
import LikeIcon from '@/components/icons/like-icon'
import StarFilledIcon from '@/components/icons/star-icon'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Table, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import getListReviews from '../api'
import defaultAvatar from '../assets/Group.jpg'
import RatingCommentRow from './RatingCommentRow'
import RatingStatusRow from './RatingStatusRow'
import RatingTableRow from './RatingTableRow'

function RatingTable(): JSX.Element {
  const [activeTab, setActiveTab] = useState('active')

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['getListReviews', activeTab],
    queryFn: (): any => getListReviews({ status: activeTab }),
  })

  const columns = [
    {
      title: 'Имя',
      render: (val: any) => (
        <Flex gap={12} className="items-center">
          <Flex className="min-w-[46px]">
            {val?.user?.avatar ? (
              <BlurImage
                src={val?.user?.avatar}
                preview={false}
                width={46}
                height={46}
                className="rounded-full overflow-hidden"
              />
            ) : (
              <BlurImage
                src={defaultAvatar}
                preview={false}
                width={46}
                height={46}
                className="rounded-full overflow-hidden"
              />
            )}
          </Flex>

          <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF] text-nowrap">
            {val?.user?.first_name ?? ''} {val?.user?.last_name ?? ''}
            {!val?.user?.first_name && !val?.user?.last_name && 'Аноним'}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Оценка',
      render: (val: any) => (
        <Flex vertical gap={16}>
          <Flex gap={2}>
            <StarFilledIcon
              width="20"
              height="20"
              style={{
                color: val?.rating > 0 ? '#FA8F54' : '#A9A9A9',
              }}
            />
            <StarFilledIcon
              width="20"
              height="20"
              style={{
                color: val?.rating > 1 ? '#FA8F54' : '#A9A9A9',
              }}
            />
            <StarFilledIcon
              width="20"
              height="20"
              style={{
                color: val?.rating > 2 ? '#FA8F54' : '#A9A9A9',
              }}
            />
            <StarFilledIcon
              width="20"
              height="20"
              style={{
                color: val?.rating > 3 ? '#FA8F54' : '#A9A9A9',
              }}
            />
            <StarFilledIcon
              width="20"
              height="20"
              style={{
                color: val?.rating > 4 ? '#FA8F54' : '#A9A9A9',
              }}
            />
          </Flex>

          <Flex gap={16}>
            <Flex className="items-center" gap={8}>
              <LikeIcon />
              <Typography.Text className="text-[#A9A9A9] font-medium text-sm">
                {val?.likes}
              </Typography.Text>
            </Flex>

            <Flex className="items-center" gap={8}>
              <DislikeIcon />
              <Typography.Text className="text-[#A9A9A9] font-medium text-sm">
                {val?.dislikes}
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>
      ),
    },
    {
      title: 'Комментарий',
      render: (val: any) => <RatingCommentRow item={val} />,
    },
    {
      title: 'Дата публикации',
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:!text-[#FFF]">
          {val?.created_at && format(new Date(val?.created_at), 'dd.MM.yyyy')}
        </Typography.Text>
      ),
    },
    {
      title: 'Статус',
      render: (val: any) => <RatingStatusRow val={val} />,
    },
    {
      title: 'Действия',
      width: 100,
      fixed: 'right',
      render: (val: any) => <RatingTableRow item={val} refetch={refetch} />,
    },
  ]

  return (
    <Flex vertical className="w-full" gap={24}>
      <Flex className="w-full border-[2px] border-solid border-x-0 border-t-0 border-[#E5E7EB] dark:border-[#272B30]">
        <Button
          className="text-[#A9A9A9] text-base font-medium rounded-none"
          style={{
            color: activeTab === 'active' ? '#635BFF' : '#A9A9A9',
            borderBottom: activeTab === 'active' ? '2px solid #635BFF' : 'none',
          }}
          type="link"
          onClick={() => {
            setActiveTab('active')
          }}
        >
          Активные
        </Button>
        <Button
          className="text-[#A9A9A9] text-base font-medium rounded-none"
          style={{
            color: activeTab === 'inactive' ? '#635BFF' : '#A9A9A9',
            borderBottom:
              activeTab === 'inactive' ? '2px solid #635BFF' : 'none',
          }}
          type="link"
          onClick={() => {
            setActiveTab('inactive')
          }}
        >
          Архивированные
        </Button>
      </Flex>

      <Flex vertical className="w-full mt-[16px]">
        <Table
          columns={columns as any}
          dataSource={data}
          scroll={{ x: 'max-content' }}
          loading={isLoading}
          bordered
        />
      </Flex>
    </Flex>
  )
}

export default RatingTable
