import { Typography } from 'antd'
import React, { useMemo } from 'react'

export default function RatingStatusRow({ val }: any): JSX.Element {
  const statusGenerator = useMemo(() => {
    if (val?.status === 'active') {
      return 'Активный'
    }
    if (val?.status === 'deleted') {
      return 'Удален'
    }
    return 'Неактивный'
  }, [val])
  
  return (
    <Typography.Text
      style={{
        color: val?.status === 'active' ? '#58C27D' : '#CE5A67',
        backgroundColor: val?.status === 'active' ? '#DEF3E5' : '#F3DCDF',
      }}
      className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px] dark:bg-[#233332] dark:text-[#58C27D]"
    >
      • {statusGenerator}
    </Typography.Text>
  )
}
