import BlurImage from '@/components/BlurImage'
import EditIcon from '@/components/icons/edit-icon'
import { Button, Divider, Flex, Typography } from 'antd'
import { useState } from 'react'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import InfrastructureModal from './AllModalsRCPage/InfrastructureModal'

export default function LocationSection({
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [infrastructureModal, setInfrastructureModal] = useState(false)

  return (
    <>
      <Flex
        gap={24}
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#272B30] dark:border-[#272B30]"
      >
        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            Расположение и инфраструктура
          </Typography.Text>
          <Button
            onClick={() => {
              setInfrastructureModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        {/* <Flex className="grid grid-cols-2" gap={20}>
          <Flex className="items-center justify-between w-full">
            <Typography.Text className="text-[#373737] text-base font-normal">
              Регион
            </Typography.Text>
            <Flex className="items-center" gap={8}>
              <Typography.Text className="font-medium text-base text-[#635BFF]">
                Ташкент
              </Typography.Text>
              <ArrowRightUpIcon
                style={{
                  color: '#635BFF',
                }}
              />
            </Flex>
          </Flex>

          <Flex className="items-center justify-between w-full">
            <Typography.Text className="text-[#373737] text-base font-normal">
              Регион
            </Typography.Text>
            <Flex className="items-center" gap={8}>
              <Typography.Text className="font-medium text-base text-[#635BFF]">
                Ташкент
              </Typography.Text>
              <ArrowRightUpIcon
                style={{
                  color: '#635BFF',
                }}
              />
            </Flex>
          </Flex>

          <Flex className="items-center justify-between w-full">
            <Typography.Text className="text-[#373737] text-base font-normal">
              Регион
            </Typography.Text>
            <Flex className="items-center" gap={8}>
              <Typography.Text className="font-medium text-base text-[#635BFF]">
                Ташкент
              </Typography.Text>
              <ArrowRightUpIcon
                style={{
                  color: '#635BFF',
                }}
              />
            </Flex>
          </Flex>

          <Flex className="items-center justify-between w-full">
            <Typography.Text className="text-[#373737] text-base font-normal">
              Регион
            </Typography.Text>
            <Flex className="items-center" gap={8}>
              <Typography.Text className="font-medium text-base text-[#635BFF]">
                Ташкент
              </Typography.Text>
              <ArrowRightUpIcon
                style={{
                  color: '#635BFF',
                }}
              />
            </Flex>
          </Flex>
        </Flex> */}

        <Flex>
          <Flex className="w-full h-[400px] overflow-hidden">
            <YMaps>
              <Map
                defaultState={{
                  center: [data?.latitude, data?.longitude],
                  zoom: 14,
                }}
                width="100%"
                height="400px"
              >
                <ZoomControl />
                <Placemark geometry={[data?.latitude, data?.longitude]} />
              </Map>
            </YMaps>
          </Flex>
        </Flex>

        <Divider className="m-0" />

        <Flex vertical>
          <Typography.Text className="text-[#1E1E1E] font-medium text-xl mb-[24px]">
            По ориентиру
          </Typography.Text>

          <Flex gap={24} className="flex-wrap">
            {data?.landmarks?.map((landmark: any) => (
              <Flex
                key={landmark?.id}
                className="items-center p-[4px] border-[1px] border-[#EEEEEE] border-solid rounded-full pr-[40px]"
                gap={10}
              >
                <Flex className="w-[48px] h-[48px] rounded-full bg-[#F1F2F4] items-center justify-center overflow-hidden">
                  <BlurImage src={landmark?.icon} preview={false} />
                </Flex>

                <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                  {landmark?.title}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <InfrastructureModal
        setInfrastructureModal={setInfrastructureModal}
        infrastructureModal={infrastructureModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />
    </>
  )
}
