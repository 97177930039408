/* eslint-disable @typescript-eslint/no-floating-promises */
import HFDatePicker from '@/components/FormElements/HFDatePicker'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { createBlock, updateBlock } from '../api'

function HouseAddDrawer({
  setAddDrawerOpen,
  addDrawerOpen,
  refetch,
  house = {},
}: any): JSX.Element {
  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: {
      ...house,
    },
  })
  const showMessage = useMessage()

  const { mutate: mutateCreateCredit, isLoading: isLoadingCreatingCredit } =
    useMutation({
      mutationFn: createBlock,
      onSuccess: async (res) => {
        await refetch()
        setAddDrawerOpen(false)
        await showMessage('success', 'Дом успешно создан.')
        reset({})
      },
      onError: async () => {
        await showMessage(
          'error',
          'Ошибка при создание кредита. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const { mutate: mutateUpdateCredit, isLoading: isLoadingUpdateCredit } =
    useMutation({
      mutationFn: updateBlock,
      onSuccess: async (res) => {
        await refetch()
        setAddDrawerOpen(false)
        await showMessage('success', 'Дом успешно обновлен.')
      },
      onError: async () => {
        await showMessage(
          'error',
          'Ошибка при обновление дома. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const submitFn = async (data: any): Promise<void> => {
    if (house?.id) {
      mutateUpdateCredit({
        ...getValues(),
      })
    } else {
      mutateCreateCredit({
        ...getValues(),
      })
    }
  }

  return (
    <Drawer
      onClose={() => {
        setAddDrawerOpen(false)
        reset({})
      }}
      open={addDrawerOpen}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] dark:border-[#272B30] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFF]">
              Добавить новый дом
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setAddDrawerOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] dark:bg-[#272B30] dark:text-[#A9A9A9]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Номер дома">
              <HFInput
                control={control}
                name="title"
                placeholder="Например: 1"
                required
              />
            </FRow>

            <FRow label="Количество этажей">
              <HFInput
                type="number"
                control={control}
                name="floor_count"
                placeholder="Введите количество этажей"
                required
              />
            </FRow>

            <FRow label="Срок сдачи">
              <HFDatePicker
                control={control}
                name="deadline"
                placeholder="Введите срок сдачи"
              />
            </FRow>

            <FRow label="Статус">
              <HFSelect
                control={control}
                name="status"
                placeholder="Выберите статус"
                required
                options={[
                  { label: 'Активный', value: 'active' },
                  { label: 'Скрытый', value: 'hidden' },
                ]}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex className="items-center justify-end p-[24px]" gap={14}>
          <Button
            onClick={() => {
              setAddDrawerOpen(false)
              reset({})
            }}
            className="w-full bg-[#FFFFFF] dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] p-[10px_24px] text-[#1E1E1E] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
          >
            <CloseIcon />
            Отмена
          </Button>

          <Button
            onClick={() => {
              handleSubmit(submitFn)()
            }}
            loading={isLoadingCreatingCredit || isLoadingUpdateCredit}
            className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
          >
            {house?.id ? 'Сохранить' : 'Создать дом'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default HouseAddDrawer
