import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Modal, Typography } from 'antd'
import DeleteImage from './LayoutStructure/assets/delete.svg'

function DeleteModal({
  deleteApproveModal,
  setDeleteApproveModal,
  onOk,
  isLoadingDeleting
}: {
  deleteApproveModal: boolean
  setDeleteApproveModal: (value: boolean) => void
  onOk: () => void
  isLoadingDeleting: boolean
}): JSX.Element {
  return (
    <Modal
      open={deleteApproveModal}
      onCancel={() => {
        setDeleteApproveModal(false)
      }}
      closeIcon={null}
      footer={null}
      className="search-modal"
    >
      <Flex
        vertical
        gap={24}
        className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid p-[24px]"
      >
        <Flex vertical className="items-center relative">
          <Image src={DeleteImage} preview={false} />

          <Typography.Title className="!text-2xl !font-medium m-0 ml-[16px] text-[#1E1E1E] text-center">
            Вы уверены, что хотите удалить этот блок?
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setDeleteApproveModal(false)
            }}
            className="absolute right-1 top-1 w-[24px] h-[24px] p-0"
          >
            <CloseCircleOutlined
              style={{
                color: '#1E1E1E',
                fontSize: 24,
              }}
            />
          </Button>
        </Flex>

        <Flex className="w-full items-center justify-center" gap={24}>
          <Button
            onClick={() => {
              setDeleteApproveModal(false)
            }}
            className="w-[130px] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#A9A9A9] font-medium text-base"
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              onOk()
            }}
            loading={isLoadingDeleting}
            className="w-[130px] bg-[#635BFF] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#FFFFFF] font-medium text-base"
          >
            Удалить
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default DeleteModal
