/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-plusplus */
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import FiltersIcon from '@/components/icons/filters'
import formatAmount from '@/helpers/formatAmount'
import useMessage from '@/hooks/useShowMessage'
import request from '@/utils/axios'
import { CloseOutlined, HomeOutlined, MoreOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { TabsProps } from 'antd'
import {
  Breadcrumb,
  Button,
  DatePicker,
  Drawer,
  Flex,
  Popover,
  Tabs,
  Typography,
} from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import getListClients, { getApartmentTypes, patchClient } from '../api'
import TableClients from '../components/Table'

function Clients(): JSX.Element {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const { control, setValue, watch, unregister, reset } = useForm()
  const [activeTab, setActiveTab] = useState('all' as string)
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const {
    data: clientsList,
    refetch: refetchList,
    isLoading,
  } = useQuery({
    queryKey: ['getListClients', activeTab, watch('filter'), page],
    queryFn: (): any =>
      getListClients({
        page,
        status: activeTab === 'all' ? null : activeTab,
        ...watch('filter'),
      }),
  })

  useEffect(() => {
    setPage(1)
  }, [
    watch('filter.status'),
    watch('filter.apartment__apartment_type'),
    watch('filter.start_date'),
    watch('filter.end_date'),
  ])

  const items: TabsProps['items'] = [
    {
      key: 'all',
      label: 'Все',
      children: (
        <TableClients
          clientsList={clientsList}
          refetchList={refetchList}
          isLoading={isLoading}
          setPage={setPage}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          page={page}
        />
      ),
    },
    {
      key: '1',
      label: 'Новый',
      children: (
        <TableClients
          clientsList={clientsList}
          refetchList={refetchList}
          isLoading={isLoading}
          setPage={setPage}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          page={page}
        />
      ),
    },
    {
      key: '2',
      label: 'Обработано',
      children: (
        <TableClients
          clientsList={clientsList}
          refetchList={refetchList}
          isLoading={isLoading}
          setPage={setPage}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          page={page}
        />
      ),
    },
    // {
    //   key: '3',
    //   label: 'Не обработано',
    //   children: (
    //     <TableClients
    //       clientsList={clientsList}
    //       refetchList={refetchList}
    //       isLoading={isLoading}
    //       setPage={setPage}
    //       selectedRowIds={selectedRowIds}
    //       setSelectedRowIds={setSelectedRowIds}
    //     />
    //   ),
    // },
    {
      key: '4',
      label: 'Архивировано',
      children: (
        <TableClients
          clientsList={clientsList}
          refetchList={refetchList}
          isLoading={isLoading}
          setPage={setPage}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
      ),
    },
  ]

  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  const { data: apartmenTypeList } = useQuery({
    queryKey: ['getApartmentTypes'],
    queryFn: getApartmentTypes,
  })

  const filterCount = useMemo(() => {
    let count = 0
    if (watch('filter.status')) {
      count++
    }
    if (watch('filter.apartment__apartment_type')) {
      count++
    }
    return count
  }, [watch('filter.status'), watch('filter.apartment__apartment_type')])

  const exportExcel = (): void => {
    request({
      url: '/building/export-clients',
      method: 'get',
      responseType: 'arraybuffer',
      params: {
        status: activeTab === 'all' ? null : activeTab,
        ids: selectedRowIds,
        ...watch('filter'),
      },
      headers: {
        'Content-Type': 'blob',
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const showMessage = useMessage()
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

  const { mutate: mutateUpdateClient } = useMutation({
    mutationFn: patchClient,
    onSuccess: () => {
      showMessage('success', 'Клиент успешно обновлен.')
      refetchList()
    },
    onError: () => {
      showMessage('error', 'Ошибка при обновлении клиента.')
    },
  })

  const changeStatus = (status: number): void => {
    setIsLoadingUpdate(true)
    selectedRowIds.forEach((id: number) => {
      mutateUpdateClient({ id, status })
    })
    setIsLoadingUpdate(false)
  }

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Главная
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Клиенты
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        <Flex className="items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 dark:!text-[#FFF]">
            Клиенты ({formatAmount(clientsList?.count ?? 0)})
          </Typography.Title>

          <Flex gap={16}>
            <DatePicker.RangePicker
              placeholder={['От', 'До']}
              className="border-[#E5E7EB] rounded-[8px] h-[48px] dark:!bg-[#272B30] dark:border-[#272B30]"
              onChange={(_date, dateString) => {
                setValue('filter.start_date', dateString[0])
                setValue('filter.end_date', dateString[1])
              }}
            />

            <Button
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:!bg-[#272B30] dark:border-[#272B30]"
              onClick={() => {
                setDrawerOpen(true)
              }}
            >
              <FiltersIcon
                style={{
                  color: isDarkMode ? '#FFF' : '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              {filterCount > 0 && (
                <Typography.Text
                  style={{
                    color: '#FFF',
                    backgroundColor: '#1E1E1E',
                    fontWeight: 400,
                    fontSize: '10px',
                    height: '18px',
                    width: '18px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {filterCount}
                </Typography.Text>
              )}
            </Button>

            <Popover
              className="dark:bg-[#272B30]"
              content={
                <Flex vertical className="min-w-[170px]">
                  <Popover
                    title={
                      selectedRowIds.length === 0
                        ? 'Выберите хотя бы одного клиента'
                        : ''
                    }
                  >
                    <Button
                      type="link"
                      loading={isLoadingUpdate}
                      disabled={selectedRowIds.length === 0}
                      onClick={() => {
                        changeStatus(2)
                      }}
                      className="text-[#1E1E1E] dark:text-[#FFF] dark:disabled:text-[#878787] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                    >
                      Обработано
                    </Button>
                  </Popover>
                  <Popover
                    title={
                      selectedRowIds.length === 0
                        ? 'Выберите хотя бы одного клиента'
                        : ''
                    }
                  >
                    <Button
                      type="link"
                      loading={isLoadingUpdate}
                      onClick={() => {
                        changeStatus(4)
                      }}
                      disabled={selectedRowIds.length === 0}
                      className="text-[#1E1E1E] dark:text-[#FFF] dark:disabled:text-[#878787] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                    >
                      Архивировать
                    </Button>
                  </Popover>
                  <Button
                    type="link"
                    onClick={() => {
                      exportExcel()
                    }}
                    className="text-[#1E1E1E] dark:text-[#FFF] dark:disabled:text-[#878787] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Экспорт данных
                  </Button>
                </Flex>
              }
              placement="bottomRight"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] dark:!bg-[#272B30] dark:border-[#272B30]">
                <MoreOutlined />
              </Button>
            </Popover>
          </Flex>
        </Flex>

        <Flex className="w-full">
          <Tabs
            className="w-full"
            defaultActiveKey="all"
            items={items}
            onChange={(key) => {
              setActiveTab(key)
            }}
          />
        </Flex>
      </Flex>

      <Drawer
        onClose={() => {
          setDrawerOpen(false)
        }}
        open={drawerOpen}
        headerStyle={{
          display: 'none',
        }}
      >
        <Flex
          vertical
          className="w-full h-full justify-between pb-[12px] dark:bg-[#1A1D1F]"
        >
          <Flex vertical>
            <Flex className="border-b-1 border-solid border-[#E5E7EB] dark:border-[#272B30] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
              <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFF]">
                Фильтр
              </Typography.Title>

              <Button
                type="link"
                onClick={() => {
                  setDrawerOpen(false)
                }}
                className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] dark:bg-[#272B30] dark:text-[#A9A9A9]"
              >
                <CloseOutlined />
              </Button>
            </Flex>

            <Flex vertical gap={24} className="w-full p-[24px]">
              {/* <FRow label="Статус">
                <HFSelect
                  control={control}
                  name="filter.status"
                  options={[
                    { label: 'Активные', value: '1' },
                    { label: 'Обработанные', value: '2' },
                    { label: 'Не обработанные', value: '3' },
                    { label: 'Архивированные', value: '4' },
                  ]}
                />
              </FRow> */}

              <FRow label="Планировка">
                <HFSelect
                  control={control}
                  name="filter.apartment__apartment_type"
                  options={apartmenTypeList?.map((item: any) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                />
              </FRow>
            </Flex>
          </Flex>

          <Flex className="items-center justify-end px-[24px]" gap={14}>
            <Button
              onClick={() => {
                setDrawerOpen(false)
                reset()
                unregister()
              }}
              className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#1E1E1E] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
            >
              Сбросить
            </Button>

            <Button className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center">
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default Clients
