import FRow from '@/components/FormElements/HFRow'
import Camera2Icon from '@/components/icons/camera-icon2'
import CloseIcon from '@/components/icons/close'
import { Button, Flex, Image, Typography } from 'antd'
import { useEffect } from 'react'
import GalleryImage from '../../assets/gallery.svg'

function GallerySection({ setValue, watch }: any): JSX.Element {
  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = (image: any): any => {
    if (isValidURL(image)) {
      return image
    }
    if (image instanceof File) {
      return URL.createObjectURL(image)
    }
    return ''
  }

  const handleFileChange = (e: Event): void => {
    const target = e.target as HTMLInputElement
    const { files } = target

    if (files != null) {
      const filesArray = Array.from(files).map((file) => ({ image: file }))

      setValue('images', [...filesArray, ...(watch('images') ?? [])])
    }
  }

  const inputClick = (): void => {
    const inputElement = document.querySelector(
      'input[id="gallery"]',
    ) as HTMLInputElement
    inputElement?.click()
  }

  useEffect(() => {
    const inputElement = document.querySelector(
      'input[id="gallery"]',
    ) as HTMLInputElement
    if (inputElement) {
      inputElement.addEventListener('change', handleFileChange)
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('change', handleFileChange)
      }
    }
  }, [])

  return (
    <section id="gallery">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#2F3336]">
          <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
            Галерея
          </Typography.Title>
        </Flex>

        <Flex vertical gap={24} className="pt-[24px]">
          <FRow label="Добавить фотографии">
            <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] dark:border-[#2F3336]">
              <input
                type="file"
                hidden
                multiple
                id="gallery"
                accept="image/jpeg, image/png, image/jpg, image/webp"
              />
              {watch('images')?.length > 0 ? (
                <Flex
                  className="items-center justify-start min-h-[200px] w-full p-5 flex-wrap"
                  gap={16}
                >
                  {watch('images')?.map((fileObj: any, index: number) => (
                    <Flex
                      key={fileObj.image.name}
                      className="relative rounded-[8px] overflow-hidden w-[250px] min-w-[250px] h-[250px]"
                    >
                      <Image
                        width="100%"
                        height="100%"
                        src={imageUrlGenerator(fileObj.image)}
                        preview
                        className="object-cover"
                      />
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          const newImages = watch('images')?.filter(
                            (img: any, i: number) => i !== index,
                          )
                          setValue('images', newImages)
                        }}
                        className="border-none p-[12px] absolute bg-[#E0DEFF] right-1 top-1 text-[#1E1E1E] rounded-[12px] text-base font-normal h-auto shadow-none text-start hover:!bg-[#F1F2F4] dark:bg-[#272B30] hover:dark:!bg-[#272B30] dark:text-[#FFF] dark:hover:!text-[#FFF]"
                      >
                        <CloseIcon />
                      </Button>
                    </Flex>
                  ))}

                  <Flex
                    vertical
                    onClick={inputClick}
                    className="rounded-[8px] cursor-pointer overflow-hidden w-[250px] min-w-[250px] h-[250px] border-[1.5px] border-[#635BFF] border-dashed items-center justify-center"
                    gap={17}
                  >
                    <Camera2Icon />
                    Добавить ещё
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  onClick={inputClick}
                  vertical
                  className="items-center justify-center min-h-[200px] w-full"
                >
                  <Image width={70} src={GalleryImage} preview={false} />
                  <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px] dark:!text-[#878787]">
                    Выберите или перетащите минимум 3 фото
                  </Typography.Text>
                  <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                    Максимальный размер: 5 МБ.
                  </Typography.Text>
                </Flex>
              )}
            </Flex>
          </FRow>
        </Flex>
      </Flex>
    </section>
  )
}

export default GallerySection
