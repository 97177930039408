import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import CarMetroIcon from '@/components/icons/car-icon'
import WalkingMetroIcon from '@/components/icons/walking-to-metro'
import { useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Divider, Flex, Typography } from 'antd'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { getListLandmarks, getListMetros, getListRegions } from '../../api'
import axios from 'axios'
import HFInput from '@/components/FormElements/HFInput'

function LocationSection({ control, setValue, watch }: any): JSX.Element {
  const { data: regions } = useQuery({
    queryKey: ['regions'],
    queryFn: getListRegions,
  })

  const { data: metros } = useQuery({
    queryKey: ['metros'],
    queryFn: getListMetros,
  })

  const { data: landmarks } = useQuery({
    queryKey: ['landmarks'],
    queryFn: getListLandmarks,
  })

  const handleClickMap = (e: any): void => {
    const coords = e.get('coords')
    getNominalByCoords(coords[0], coords[1], 'ru')
    getNominalByCoords(coords[0], coords[1], 'uz')

    setValue('latitude', coords[0])
    setValue('longitude', coords[1])
  }

  const longitude = watch('longitude')
  const latitude = watch('latitude')
  const isCoordsValid = longitude !== undefined && latitude !== undefined

  const getNominalByCoords = async (
    lat: number,
    long: number,
    lang: string,
  ): any => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`

    try {
      const response = await axios.get(url, {
        headers: {
          'Accept-Language': lang,
        },
      })
      const { data: dataField } = response

      if (dataField.display_name) {
        setValue(`address_${lang}`, dataField.display_name)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section id="location">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#2F3336]">
          <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
            Расположение ЖК
          </Typography.Title>
        </Flex>

        <Flex vertical gap={24} className="pt-[24px]">
          <Flex gap={16}>
            <FRow label="Регион">
              <HFSelect
                control={control}
                required
                name="region"
                options={regions?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Район">
              <HFSelect
                control={control}
                required
                name="district"
                options={regions
                  ?.find((item: any) => item.id === watch('region'))
                  ?.districts.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
              />
            </FRow>
          </Flex>

          <Flex gap={16}>
            <FRow label="Адрес (РУ)">
              <HFInput control={control} required name="address_ru" />
            </FRow>

            <FRow label="Адрес (O’Z)">
              <HFInput control={control} required name="address_uz" />
            </FRow>

            <FRow label="Адрес (ЎЗ)">
              <HFInput control={control} required name="address_cyrill" />
            </FRow>
          </Flex>

          <Flex className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid h-[500px]">
            <YMaps>
              <Map
                onClick={handleClickMap}
                width="100%"
                height="500px"
                defaultState={{ center: [41.311081, 69.240562], zoom: 12 }}
              >
                <ZoomControl />
                {isCoordsValid && (
                  <Placemark
                    geometry={[watch('latitude'), watch('longitude')]}
                  />
                )}
              </Map>
            </YMaps>
          </Flex>

          {watch('region') === 3 && (
            <Flex gap={24}>
              <FRow label="Ближайшее метро">
                <HFSelect
                  control={control}
                  allowClear
                  name="nearby_metros.0.metro"
                  placeholder="Выберите метро"
                  className="!h-full"
                  options={metros?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </FRow>

              <Flex vertical>
                <Flex className="text-[#1E1E1E] text-sm font-medium mb-[10px] dark:!text-[#FFF]">
                  Расстояние до метро
                </Flex>
                <Flex className="p-[3px] bg-[#F1F2F4] rounded-[8px] w-max h-[40px]">
                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.type') === 1
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metros.0.type', 1)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <WalkingMetroIcon />
                  </Button>

                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.type') === 2
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metros.0.type', 2)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <CarMetroIcon />
                  </Button>
                </Flex>
              </Flex>

              <Flex vertical>
                <Flex className="h-[24px] text-[#1E1E1E] text-sm font-medium ">
                  Время до метро
                </Flex>
                <Flex className="w-full" gap={12}>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 5)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 5
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    5 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 10)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 10
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    10 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 15)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 15
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    15 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metros.0.time', 20)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metros.0.time') === 20
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    20 мин.
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Divider className="m-0" />

          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                По ориентиру
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {landmarks?.length > 0 ? (
                <Checkbox.Group
                  options={landmarks?.map((item: any) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  className="w-full grid grid-cols-4 gap-[16px] text-[#1E1E1E]"
                  value={watch('landmarks')}
                  onChange={(values) => {
                    setValue('landmarks', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет доступных ориентиров
                </Typography.Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </section>
  )
}

export default LocationSection
