import CarMetroIcon from '@/components/icons/car-icon'
import DotIcon from '@/components/icons/dot-icon'
import EditIcon from '@/components/icons/edit-icon'
import LocationIcon from '@/components/icons/location-icon'
import MetroIcon2 from '@/components/icons/metro-icon2'
import WalkingMetroIcon from '@/components/icons/walking-to-metro'
import { Button, Divider, Flex, Typography } from 'antd'
import { useState } from 'react'
import AboutModal from './AllModalsRCPage/AboutModal'
import CharacteristicsModal from './AllModalsRCPage/CharacteristicsModal'

export default function InformationsSection({
  isDarkMode,
  data,
  refetch,
  showMessage,
}: {
  isDarkMode: boolean
  data: any
  refetch: any
  showMessage: any
}): JSX.Element {
  const [characteristicsModal, setCharacteristicsModal] = useState(false)
  const [aboutModal, setAboutModal] = useState(false)

  return (
    <>
      <Flex
        gap={20}
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#272B30] dark:border-[#272B30]"
      >
        <Flex vertical>
          <Flex gap={8} className="items-center">
            <Typography.Text className="text-[#1E1E1E] font-medium text-[32px] dark:text-[#FFF]">
              {data?.district?.name}
            </Typography.Text>
          </Flex>

          <Flex gap={10} className="items-center mt-[16px] mb-[12px]">
            <LocationIcon
              style={{
                color: isDarkMode ? '#FFF' : '#373737',
              }}
            />
            <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#FFF]">
              {data?.address ??
                data?.address_ru ??
                data?.address_uz ??
                data?.address_cyrill ??
                'Адрес не указан'}
            </Typography.Text>
          </Flex>

          <Flex gap={10} className="items-center">
            <Flex gap={6}>
              <MetroIcon2
                style={{
                  width: '20px',
                  height: '20px',
                  color: isDarkMode ? '#FFF' : '#373737',
                }}
              />
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#FFF]">
                {data?.nearby_metros?.[0]?.name}
              </Typography.Text>
            </Flex>

            <DotIcon
              style={{
                width: '6px',
                height: '6px',
                color: isDarkMode ? '#FFF' : '#373737',
              }}
            />

            <Flex gap={6}>
              {data?.nearby_metros?.[0]?.type === 1 ? (
                <WalkingMetroIcon />
              ) : (
                <CarMetroIcon />
              )}
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#FFF]">
                {data?.nearby_metros?.[0]?.time} мин.
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>

        <Divider className="m-0" />

        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            О ЖК «{data?.name}»
          </Typography.Text>
          <Button
            onClick={() => {
              setAboutModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        <Flex>
          <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
            {data?.description}
          </Typography.Text>
        </Flex>

        <Divider className="m-0" />

        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            Характеристики
          </Typography.Text>
          <Button
            onClick={() => {
              setCharacteristicsModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        <Flex className="grid grid-cols-2" gap={140}>
          <Flex vertical gap={12}>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Класс жилья
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.class_type?.title}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Тип отделки
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.house_decoration?.title}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Материал стен
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.house_type?.title}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Высота потолков
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.ceiling_height?.ceiling_height} м
              </Typography.Text>
            </Flex>
          </Flex>

          <Flex vertical gap={12}>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Парковка
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.parking?.title}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Срок сдачи
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.deadline?.min}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Кол-во домов
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.block_count}
              </Typography.Text>
            </Flex>
            <Flex className="item-center justify-between">
              <Typography.Text className="text-[#373737] font-normal text-base dark:text-[#A9A9A9]">
                Кол-во квартир
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
                {data?.apartment_count}
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <AboutModal
        setAboutModal={setAboutModal}
        aboutModal={aboutModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />

      <CharacteristicsModal
        setCharacteristicsModal={setCharacteristicsModal}
        characteristicsModal={characteristicsModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />
    </>
  )
}
