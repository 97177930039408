/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import BlurImage from '@/components/BlurImage'
import { Flex, Table, Typography } from 'antd'
import DefaultAvatar from '../assets/avatar.svg'
import InstagramIcon from '../assets/instagram.svg'
import TelegramIcon from '../assets/telegram.svg'
import TableRow from './TableRow'

function TableContacts({ data, isLoading, setDrawerOpen }: any): JSX.Element {
  const columns = [
    {
      title: 'Имя',
      render: (val: any) => (
        <Flex className="items-center gap-[12px]">
          <BlurImage
            src={val?.image ?? DefaultAvatar}
            preview={false}
            width={46}
            height={46}
            className="rounded-full overflow-hidden"
          />
          <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
            {val?.first_name} {val?.last_name}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Телефон номер',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
          {val?.phone}
        </Typography.Text>
      ),
    },
    {
      title: 'Электронная почта',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
          {val?.email}
        </Typography.Text>
      ),
    },
    {
      title: 'Соц. сети',
      width: 400,
      render: (val: any) => (
        <Flex vertical>
          <Flex gap={8}>
            <BlurImage
              src={TelegramIcon}
              preview={false}
              width={20}
              height={20}
            />
            <Typography.Text>{val?.telegram}</Typography.Text>
          </Flex>

          <Flex gap={8}>
            <BlurImage
              src={InstagramIcon}
              preview={false}
              width={20}
              height={20}
            />
            <Typography.Text>{val?.instagram}</Typography.Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: 'Действия',
      width: 100,
      fixed: 'right',
      render: (val: any) => <TableRow item={val} />,
    },
  ]

  return (
    <Flex vertical className="w-full mt-[16px]">
      <Table
        columns={columns as any}
        dataSource={data}
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        bordered
      />
    </Flex>
  )
}

export default TableContacts
