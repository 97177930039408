import DotIcon from '@/components/icons/dot-icon'
import DoubleTickIcon from '@/components/icons/double-tick'
import NotificationCircledIcon from '@/components/icons/notification-icon'
import getListReviews from '@/features/reviews/api'
import { UserOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Avatar, Badge, Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'

function NotificationsPopover({
  isDarkMode,
}: {
  isDarkMode: boolean
}): JSX.Element {
  const [open, setOpen] = useState(false)

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { data, isLoading } = useQuery({
    queryKey: ['getListReviews'],
    queryFn: (): any => getListReviews({
      is_read: false,
    }),
  })

  return (
    <Popover
      overlayInnerStyle={{
        backgroundColor: isDarkMode ? '#272B30' : '#FFF',
        padding: '0',
        minWidth: '500px',
        width: '500px',
      }}
      placement="bottomRight"
      content={
        <Flex
          vertical
          className="border-[1px] border-solid rounded-[12px] border-[#E5E7EB] dark:border-[#272B30] dark:bg-[#1A1D1F]"
        >
          <Flex className="p-[24px] items-center justify-between w-full border-[1px] border-solid border-x-0 border-t-0 border-[#E5E7EB] dark:border-[#272B30]">
            <Typography.Text className="text-[#1E1E1E] font-medium text-2xl flex items-center gap-1 dark:text-[#FFF]">
              Уведомления
              <Typography.Text className="text-[#A9A9A9] font-medium text-2xl">
                ({data?.length})
              </Typography.Text>
            </Typography.Text>

            {/* <Button className="border-[1px] border-solid border-[#E5E7EB] dark:border-[#635BFF] rounded-[8px] p-[14px_16px] flex items-center gap-[10px] h-auto dark:bg-[#1A1D1F] dark:text-[#FFF]">
              <DoubleTickIcon />
              Прочитать все
            </Button> */}
          </Flex>

          <Flex className="overflow-y-auto max-h-[400px]" vertical>
            {data?.map((item: any) => (
              <Flex key={item?.id}>
                <Flex
                  gap={24}
                  className="p-[24px] border-b-1 border-[#E5E7EB] border-solid border-t-0 border-x-0 dark:border-[#272B30]"
                >
                  <Flex gap={8} className="items-center h-max">
                    <DotIcon
                      style={{
                        width: '8px',
                        height: '8px',
                        color: '#635BFF',
                      }}
                    />

                    <Flex className="w-[46px] h-[46px]">
                      <Avatar size={46} icon={<UserOutlined />} />
                    </Flex>
                  </Flex>

                  <Flex vertical>
                    <Flex vertical gap={4} className="mb-[12px]">
                      <Typography.Text className="!font-normal !text-sm !text-[#A9A9A9]">
                        {item?.created_at &&
                          format(
                            new Date(item?.created_at),
                            'dd.MM.yyyy HH:mm',
                          )}
                      </Typography.Text>

                      <Typography.Text className="!font-medium !text-base !text-[#1E1E1E] dark:!text-[#FFF]">
                        {item?.full_name}
                      </Typography.Text>

                      <Typography.Text className="!font-normal !text-sm !text-[#A9A9A9]">
                        оставил (-а) комментарий
                      </Typography.Text>
                    </Flex>

                    <Flex className="pl-[24px] border-l-2 border-[#E5E7EB] border-solid border-y-0 border-r-0 mb-[12px] dark:border-[#272B30]">
                      <Typography.Text className="!font-normal !text-sm !text-[#373737] dark:!text-[#FFF]">
                        {item?.comment ?? 'Комментарий отсутствует'}
                      </Typography.Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Badge
        count={data?.length}
        style={{
          backgroundColor: isDarkMode ? 'inherit' : '#F3DCDF',
          border: '1px solid #CE5A67',
          color: isDarkMode ? '#FFF' : '#1E1E1E',
        }}
      >
        <Button type="link" className="p-0 w-[38px] h-[38px]">
          <NotificationCircledIcon />
        </Button>
      </Badge>
    </Popover>
  )
}

export default NotificationsPopover
