import BlurImage from '@/components/BlurImage'
import PlusAddIcon from '@/components/icons/plus-icon'
import queryClient from '@/utils/query-client'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Divider, Flex, Modal, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import useMessage from 'antd/es/message/useMessage'
import { format } from 'date-fns'
import { useState } from 'react'
import { createComment } from '../api'

export default function ClientRowHistory({
  client,
  refetchList,
}: any): JSX.Element {
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  const [isOpenCreateCommentModal, setIsOpenCreateCommentModal] =
    useState(false)
  const [value, setValue] = useState('')
  const showMessage = useMessage()

  const { mutate: mutateCreateComment, isLoading: isLoadingCreateComment } =
    useMutation({
      mutationFn: createComment,
      onSuccess: () => {
        showMessage('success', 'Комментарий успешно добавлен.')
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
        setValue('')
        setIsOpenCreateCommentModal(false)
      },
      onError: () => {
        showMessage('error', 'Ошибка при добавлении комментария.')
      },
    })

  return (
    <>
      <Typography.Text
        onClick={() => {
          setIsOpenHistoryModal(true)
        }}
        className="text-[#635BFF] font-normal text-base dark:text-[#fff] cursor-pointer"
      >
        #{client?.id}
      </Typography.Text>

      <Modal
        open={isOpenHistoryModal}
        onOk={() => {
          setIsOpenHistoryModal(false)
        }}
        onCancel={() => {
          setIsOpenHistoryModal(false)
        }}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="750px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            История заявки
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setIsOpenHistoryModal(false)
            }}
          >
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex
          vertical
          className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex gap={24} className="items-center">
            <Flex className="w-[70px] h-[70px] overflow-hidden items-center justify-center">
              <BlurImage
                src={client?.image}
                preview={false}
                className="w-full h-full object-cover"
              />
            </Flex>
          </Flex>

          <Divider />

          <Flex gap={90}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Имя
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.full_name}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Телефон номер
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.phone_number}
              </Typography.Text>
            </Flex>
          </Flex>

          <Divider />

          <Flex vertical>
            <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF] mb-[24px]">
              История
            </Typography.Text>

            <Flex
              vertical
              gap={24}
              className="max-h-[350px] overflow-auto mb-[24px]"
            >
              {client?.comments?.length > 0 ? (
                client?.comments?.map((comment: any) => (
                  <Flex
                    key={comment?.id}
                    vertical
                    className="bg-[#F1F2F4] p-[16px] w-full rounded-[16px]"
                  >
                    <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                      {comment?.description}
                    </Typography.Text>

                    <Flex className="items-center justify-end mt-[8px]">
                      <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                        {format(
                          new Date(comment?.created_at),
                          'dd.MM.yyyy HH:mm',
                        )}
                      </Typography.Text>
                    </Flex>
                  </Flex>
                ))
              ) : (
                <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                  Нет комментариев
                </Typography.Text>
              )}
            </Flex>

            <Button
              onClick={() => {
                setIsOpenCreateCommentModal(true)
                setIsOpenHistoryModal(false)
              }}
              className="text-[#635BFF] font-medium text-base flex items-center justify-center w-full p-[16px] border border-solid border-[#E0DEFF] rounded-[14px] h-auto"
            >
              <PlusAddIcon />
              Добавить новый комментарий
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isOpenCreateCommentModal}
        onOk={() => {
          setIsOpenCreateCommentModal(false)
        }}
        onCancel={() => {
          setIsOpenCreateCommentModal(false)
        }}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="750px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            Обработка заявки
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setIsOpenCreateCommentModal(false)
            }}
          >
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex
          vertical
          className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex gap={90}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Имя
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.full_name}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Телефон номер
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.phone_number}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Планировка квартиры
              </Typography.Text>
              <Flex className="w-[70px] h-[70px] overflow-hidden items-center justify-center">
                <BlurImage
                  src={client?.image}
                  preview={false}
                  className="w-full h-full object-cover"
                />
              </Flex>
            </Flex>
          </Flex>

          <Divider />

          <Flex vertical>
            <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF] mb-[24px]">
              Комментарии
            </Typography.Text>

            <Flex
              vertical
              gap={24}
              className="max-h-[350px] overflow-auto mb-[24px]"
            >
              <TextArea
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                placeholder="Напишите комментарии..."
                className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
              />
            </Flex>
          </Flex>

          <Flex gap={24} className="justify-end">
            <Button
              onClick={() => {
                setIsOpenCreateCommentModal(false)
                setValue('')
              }}
              className="p-[16px_22px] border border-solid border-[#E5E7EB] text-base font-medium rounded-[14px] h-auto"
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                mutateCreateComment({
                  request_call: client?.id,
                  description: value,
                })
              }}
              loading={isLoadingCreateComment}
              className="p-[16px_22px] border border-solid bg-[#635BFF] border-[#635BFF] text-[#FFF] text-base font-medium rounded-[14px] h-auto"
            >
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
