/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @tanstack/query/no-deprecated-options */
import HFInput from '@/components/FormElements/HFInput'
import HFInputMask from '@/components/FormElements/HFInputMask'
import FRow from '@/components/FormElements/HFRow'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Spin, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { getContact } from '../api'
import DefaultAvatar from '../assets/avatar.svg'
import InstagramIcon from '../assets/instagram.svg'
import TelegramIcon from '../assets/telegram.svg'

function ContactFormDrawer({
  setDrawerOpen,
  drawerOpen,
  control,
  mutateCreateContact,
  getValues,
  isLoadingCreating,
  type,
  setValue,
  reset,
  handleSubmit,
}: any): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [imageErrorRequires, setImageErrorRequires] = useState(false)

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const inputClick = (): void => {
    const fileInput = document.querySelector(
      'input[type="file"]',
    ) as HTMLInputElement
    fileInput?.click()

    fileInput?.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement
      const file = target.files?.[0]

      // Validate file type (make sure it’s an image)
      if (
        file &&
        ['image/jpeg', 'image/png', 'image/webp'].includes(file.type)
      ) {
        setValue('image', file)
      } else {
        // Handle invalid file type
        console.log('Please upload a valid image file (JPEG, PNG, WEBP).')
      }
    })
  }

  useEffect(() => {
    reset()
    setImageErrorRequires(false)
  }, [drawerOpen])

  const { isFetching, refetch, data } = useQuery({
    queryKey: ['getOneContact', type, drawerOpen],
    queryFn: (): any => getContact(type),
    cacheTime: 0,
    enabled: drawerOpen && type !== 'create' && !!type,
  })

  useEffect(() => {
    const imageFile = urlToFile(data?.image)
    console.log('wewewewew', data);
    
    reset({
      ...data,
      image: imageFile,
      instagram: data?.instagram.replace('https://www.instagram.com/', ''),
      telegram: data?.telegram.replace('https://t.me/', ''),
    })
  }, [data])

  useEffect(() => {
    if (drawerOpen && type !== 'create') {
      refetch()
    }
  }, [drawerOpen])

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      if (isValidURL(watchingImage)) {
        return watchingImage
      } else if (
        watchingImage instanceof File ||
        watchingImage instanceof Blob
      ) {
        return URL.createObjectURL(watchingImage)
      }
    }
    return DefaultAvatar
  }, [watchingImage])

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()
    const urlParts = url?.split('/')
    const filename = urlParts?.[urlParts.length - 1]
    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'
    const file = new File([blob], filename, { type: mimeType })
    setLoading(false)
    return file
  }

  const handleSave = async () => {
    // if (!watchingImage) {
    //   setImageErrorRequires(true)
    //   return
    // }
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    mutateCreateContact({
      ...getValues(),
      image: imageFile,
      instagram: `https://www.instagram.com/${getValues('instagram')}`,
      telegram: `https://t.me/${getValues('telegram')}`,
    })
  }

  return (
    <Drawer
      onClose={() => {
        setDrawerOpen(false)
      }}
      open={drawerOpen}
      headerStyle={{
        display: 'none',
      }}
      width={545}
    >
      <Flex
        vertical
        className="w-full h-full justify-between relative dark:bg-[#1A1D1F]"
      >
        {isFetching && (
          <Flex className="w-full h-full absolute items-center justify-center bg-[#FFFFFFB4] z-10">
            <Spin size="large" spinning={isFetching} />
          </Flex>
        )}

        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] dark:border-[#272B30] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFFFFF]">
              {drawerOpen && type !== 'create' && !!type
                ? 'Изменить контакт'
                : 'Добавить контакт'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] dark:bg-[#272B30] dark:text-[#878787]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Добавить фотографи">
              <Flex gap={16}>
                <Flex>
                  <Flex
                    className="w-[72px] h-[72px] rounded-full bg-[#F9FAFC] items-center object-contain justify-center overflow-hidden"
                    style={{
                      border: imageErrorRequires ? '1px solid #ff4d4f' : '',
                    }}
                  >
                    <img
                      src={imageUrlGenerator}
                      alt="user"
                      className="w-[72px] h-[72px]"
                    />
                  </Flex>
                </Flex>
                <input
                  type="file"
                  hidden
                  accept="image/jpeg, image/png, image/jpg, image/webp"
                />
                <Flex vertical>
                  <Flex gap={22}>
                    <Button
                      onClick={inputClick}
                      className="bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
                    >
                      {watchingImage ? 'Изменить' : 'Выбрать'}
                    </Button>
                    {watchingImage && (
                      <Button
                        onClick={() => {
                          setValue('image', null)
                        }}
                        className="bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
                      >
                        Удалить
                      </Button>
                    )}
                  </Flex>

                  <Typography.Text className="text-[#A9A9A9] font-normal text-sm mt-[11px]">
                    Ваше изображение должно быть квадратным, размером не менее
                    100x100 пикселей и в формате JPG или PNG.
                  </Typography.Text>
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Имя контакта">
              <HFInput
                control={control}
                name="first_name"
                allowSpace={false}
                required
                rules={{
                  minLength: {
                    value: 3,
                    message: 'Минимальная длина 3 символа',
                  },
                }}
              />
            </FRow>

            <FRow label="Фамилия контакта">
              <HFInput
                control={control}
                name="last_name"
                allowSpace={false}
                required
                rules={{
                  minLength: {
                    value: 3,
                    message: 'Минимальная длина 3 символа',
                  },
                }}
              />
            </FRow>

            <FRow label="Телефон номер">
              <HFInputMask
                control={control}
                name="phone"
                required
                rules={{
                  minLength: {
                    value: 12,
                    message: 'Минимальная длина 12 символа',
                  },
                }}
              />
            </FRow>

            <FRow label="Электронная почта">
              <HFInput
                control={control}
                name="email"
                onlyLatin
                rules={{
                  validate: (value: string) =>
                    value === ''
                      ? true
                      : !value.includes('@')
                      ? 'Некорректный email'
                      : true,
                }}
              />
            </FRow>

            <FRow label="Соц. сети">
              <Flex className="flex flex-col gap-[8px]">
                <HFInput
                  control={control}
                  name="instagram"
                  onlyLatin
                  onPaste={(e: any) => {
                    e.preventDefault()
                    const pastedData = e.clipboardData.getData('text')
                    if (pastedData.includes('https://www.instagram.com/')) {
                      const data = pastedData.replace(
                        'https://www.instagram.com/',
                        '',
                      )
                      setValue('instagram', data)
                    } else {
                      setValue('instagram', pastedData)
                    }
                  }}
                  prefix="https://www.instagram.com/"
                  suffix={
                    <Flex className="items-center" gap={10}>
                      <img
                        src={InstagramIcon}
                        alt="instagram"
                        className="w-[20px] h-[20px]"
                      />
                      <Typography.Text className="text-[#A9A9A9] font-normal text-sm">
                        Instagram
                      </Typography.Text>
                    </Flex>
                  }
                  // rules={{
                  //   validate: (value: string) =>
                  //     value === ''
                  //       ? true
                  //       : !value.includes('instagram.com')
                  //       ? 'Некорректный Instagram'
                  //       : true,
                  // }}
                />
                <HFInput
                  control={control}
                  name="telegram"
                  onlyLatin
                  onPaste={(e: any) => {
                    e.preventDefault()
                    const pastedData = e.clipboardData.getData('text')

                    if (pastedData.includes('https://t.me/')) {
                      const data = pastedData.replace('https://t.me/', '')
                      setValue('telegram', data)
                    } else {
                      setValue('telegram', pastedData)
                    }
                  }}
                  prefix="https://t.me/"
                  suffix={
                    <Flex className="items-center" gap={10}>
                      <img
                        src={TelegramIcon}
                        alt="Telegram"
                        className="w-[20px] h-[20px]"
                      />
                      <Typography.Text className="text-[#A9A9A9] font-normal text-sm">
                        Telegram
                      </Typography.Text>
                    </Flex>
                  }
                  // rules={{
                  //   validate: (value: string) =>
                  //     value === ''
                  //       ? true
                  //       : !value.includes('t.me')
                  //       ? 'Некорректный Telegram'
                  //       : true,
                  // }}
                />
              </Flex>
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={22} className="w-full p-[24px]">
          <Button
            onClick={() => {
              setDrawerOpen(false)
            }}
            className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center dark:bg-[#272B30] dark:text-[#878787]"
          >
            Отменить
          </Button>
          <Button
            onClick={() => {
              handleSubmit(handleSave)()
            }}
            loading={isLoadingCreating}
            className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
          >
            {type === 'create' ? 'Создать' : 'Изменить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default ContactFormDrawer
