/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import {
  getListClassTypes,
  getListHeights,
  getListHouseDecorations,
  getListHouseTypes,
  getListParkings,
  updateRCPage,
} from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useForm } from 'react-hook-form'

function CharacteristicsModal({
  setCharacteristicsModal,
  characteristicsModal,
  data,
  refetch,
  showMessage
}: any): JSX.Element {
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      class_type: data?.class_type?.id,
      house_decoration: data?.house_decoration?.id,
      house_type: data?.house_type?.id,
      ceiling_height: data?.ceiling_height?.id,
      parking: data?.parking?.id,
    },
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: () =>
      updateRCPage(
        {
          class_type: getValues('class_type'),
          house_decoration: getValues('house_decoration'),
          house_type: getValues('house_type'),
          ceiling_height: getValues('ceiling_height'),
          parking: getValues('parking'),
        },
        data?.id,
      ),
    onSuccess: () => {
      setCharacteristicsModal(false)
      showMessage('success', 'Характеристики успешно изменены')
      refetch()
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const updateTitle = (): void => {
    mutateUpdate()
  }

  const { data: houseDecorations } = useQuery({
    queryKey: ['houseDecorations'],
    queryFn: getListHouseDecorations,
  })

  const { data: classTypes } = useQuery({
    queryKey: ['classTypes'],
    queryFn: getListClassTypes,
  })

  const { data: houseTypes } = useQuery({
    queryKey: ['houseTypes'],
    queryFn: getListHouseTypes,
  })

  const { data: parkingList } = useQuery({
    queryKey: ['parkingList'],
    queryFn: getListParkings,
  })

  const { data: heightList } = useQuery({
    queryKey: ['heightList'],
    queryFn: getListHeights,
  })

  return (
    <Modal
      open={characteristicsModal}
      onOk={() => setCharacteristicsModal(false)}
      onCancel={() => setCharacteristicsModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="80%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Характеристики
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setCharacteristicsModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex
        vertical
        gap={24}
        className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid"
      >
        <Flex gap={24} className="grid grid-cols-2">
          <FRow label="Класс жилья">
            <HFSelect
              control={control}
              name="class_type"
              options={
                classTypes?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                })) ?? []
              }
            />
          </FRow>
          <FRow label="Тип отделки">
            <HFSelect
              control={control}
              name="house_decoration"
              options={
                houseDecorations?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                })) ?? []
              }
            />
          </FRow>
          <FRow label="Материал стен">
            <HFSelect
              control={control}
              name="house_type"
              options={
                houseTypes?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                })) ?? []
              }
            />
          </FRow>

          <FRow label="Высота потолков">
            <HFSelect
              control={control}
              name="ceiling_height"
              options={
                heightList?.map((item: any) => ({
                  label: `${item?.ceiling_height} м`,
                  value: item.id,
                })) ?? []
              }
            />
          </FRow>

          <FRow label="Парковка">
            <HFSelect
              control={control}
              name="parking"
              options={
                parkingList?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                })) ?? []
              }
            />
          </FRow>
        </Flex>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setCharacteristicsModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTitle)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default CharacteristicsModal
