/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import BlurImage from '@/components/BlurImage'
import KubokIcon from '@/components/icons/kubok-icon'
import formatAmount from '@/helpers/formatAmount'
import { Checkbox, Flex, Table, Typography } from 'antd'
import FlatsTableRow from './FlatsTableRow'

function FlatsTablePage({
  data,
  setPage,
  page,
  selectedRowIds,
  setSelectedRowIds,
  watch,
  isLoading,
  setFilterDrawerOpen,
  refetch,
}: any): JSX.Element {
  const columns = [
    {
      title: (
        <Flex>
          <Checkbox
            checked={selectedRowIds.length === data?.results?.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowIds(data?.results?.map((item: any) => item.id))
              } else {
                setSelectedRowIds([])
              }
            }}
          />
        </Flex>
      ),
      width: 100,
      render: (val: any) => (
        <Flex>
          <Checkbox
            checked={selectedRowIds?.includes(val?.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowIds([...selectedRowIds, val?.id])
              } else {
                setSelectedRowIds(
                  selectedRowIds?.filter((item: any) => item !== val?.id),
                )
              }
            }}
          />
        </Flex>
      ),
    },
    {
      title: 'Тип квартиры',
      width: 400,
      render: (val: any) => {
        if (watch('filters.type') === '1') {
          return (
            <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
              {val?.apartment_type === 1 ? 'Квартира' : 'Студия'}
            </Typography.Text>
          )
        }
        return null
      },
    },
    {
      title: 'Планировка',
      width: 400,
      render: (val: any) => (
        <Flex className="cursor-pointer">
          <BlurImage
            src={val?.image}
            width={68}
            height={68}
            // onClick={() => {
            //   setIsModalOpen(true)
            // }}
          />
        </Flex>
      ),
    },
    {
      title: 'Общая площадь',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
          {val?.total_space}
        </Typography.Text>
      ),
    },
    {
      title:
        watch('filters.type') === '1' ? 'Площадь кухни' : 'Полезная площадь',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
          {val?.kitchen_area ?? 0}
        </Typography.Text>
      ),
    },
    {
      title: 'Цена за м2',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
          {formatAmount(val?.price ?? 0)} uzs
        </Typography.Text>
      ),
    },
    {
      title: 'Номер дома',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
          {val?.block}
        </Typography.Text>
      ),
    },
    {
      title: 'Доступно',
      width: 400,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
          {val?.count} шт.
        </Typography.Text>
      ),
    },
    {
      title: 'Теги',
      width: 400,
      render: (val: any) => {
        val?.special_offer > 0 ? (
          <Flex className="items-center bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[5px] overflow-hidden">
            <KubokIcon />

            <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
              Спецпредложения
            </Typography.Text>
          </Flex>
        ) : (
          <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
            Нет
          </Typography.Text>
        )
      },
    },
    {
      title: 'Действия',
      width: 100,
      fixed: 'right',
      render: (val: any) => (
        <FlatsTableRow flat={val} key={val?.id} refetch={refetch} />
      ),
    },
  ]

  return (
    <Flex vertical>
      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Flex vertical className="w-full mt-[16px]">
          <Table
            columns={columns as any}
            dataSource={data?.results}
            scroll={{ x: 'max-content' }}
            loading={isLoading}
            bordered
            pagination={{
              current: page || 1,
              onChange: (page) => {
                setPage(page)
              },
              total: data?.count,
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FlatsTablePage
