/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import request from '@/utils/axios'
import requestV1 from '@/utils/axiosForV1'

export default async function getListBlocks(params: any): Promise<any> {
  const res: any = await request({
    url: '/building/block',
    method: 'get',
    params,
  })

  return res
}

export async function createBlock(data: any): Promise<any> {
  const res: any = await request({
    url: '/building/block',
    method: 'post',
    data,
  })

  return res
}

export async function updateBlock(data: any): Promise<any> {
  const res: any = await request({
    url: `/building/block/${data.id}`,
    method: 'put',
    data,
  })

  return res
}

export async function deleteBlock(id: number): Promise<any> {
  const res: any = await request({
    url: `/building/block/${id}`,
    method: 'delete',
  })

  return res
}

export async function getListApartments(params: any): Promise<any> {
  const res: any = await request({
    url: '/building/block-apartment',
    method: 'get',
    params,
  })

  return res
}

export async function createFlat(data: any): Promise<any> {
  const res: any = await request({
    url: '/building/apartment-create',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function updateFlat(data: any): Promise<any> {
  const res: any = await request({
    url: `/building/apartment/${data.id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function flatGetById(id: number): Promise<any> {
  const res: any = await request({
    url: `/building/apartment/${id}`,
    method: 'get',
  })

  return res
}

export async function deleteFlat(id: number): Promise<any> {
  const res: any = await request({
    url: `/building/apartment/${id}`,
    method: 'delete',
  })

  return res
}

function arrayToQueryString(array: [number]): string {
  return array.map((id) => `ids=${id}`).join('&')
}

export async function multipleDeleteFlats(data: any): Promise<any> {
  const res: any = await request({
    url: `/building/delete-apatments?${arrayToQueryString(data)}`,
    method: 'get',
  })

  return res
}

function flattenObject(
  obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any },
  prefix = '',
): any[] {
  let items: any[] = []

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const newKey = prefix ? `${prefix}${key}` : key

      if (
        value &&
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value instanceof File) &&
        !(value instanceof Blob)
      ) {
        items = items.concat(flattenObject(value, newKey))
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item && typeof item === 'object' && !Array.isArray(item)) {
            items = items.concat(flattenObject(item, `${newKey}[${index}]`))
          } else {
            items.push({ key: `${newKey}`, value: item })
          }
        })
      } else {
        items.push({ key: newKey, value })
      }
    }
  }

  return items
}

function appendToFormData(formData: FormData, data: any): void {
  const flattened = flattenObject(data)
  flattened.forEach(({ key, value }: any) => {
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value)
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Array)
    ) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  })
}

export async function createRCPage(data: any): Promise<any> {
  const formData = new FormData()

  appendToFormData(formData, data)

  const res: any = await request({
    url: '/building/building',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function updatePrice(data: any): Promise<any> {
  const res: any = await request({
    url: '/building/update-prices',
    method: 'post',
    data,
  })

  return res
}

export async function getRCPage(): Promise<any> {
  const res: any = await request({
    url: '/building/buildings',
    method: 'get',
  })

  return res
}

export async function updateRCPage(data: any, id: any): Promise<any> {
  const formData = new FormData()

  appendToFormData(formData, data)

  const res: any = await request({
    url: `/building/building/${id}`,
    method: 'patch',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function getListRegions(): Promise<any> {
  const res: any = await request({
    url: '/base/regions',
    method: 'get',
  })

  return res
}

export async function getListMetros(): Promise<any> {
  const res: any = await request({
    url: `/base/metros`,
    method: 'get',
  })

  return res
}

export async function getListLandmarks(): Promise<any> {
  const res: any = await request({
    url: `/building/landmark`,
    method: 'get',
  })

  return res
}

export async function getListInteriorFeatures(): Promise<any> {
  const res: any = await request({
    url: `/building/interior-features`,
    method: 'get',
  })

  return res
}

export async function getListSafetySystem(): Promise<any> {
  const res: any = await request({
    url: `/building/safety-system`,
    method: 'get',
  })

  return res
}

export async function getListExteriorFeatures(): Promise<any> {
  const res: any = await request({
    url: `/building/exterior-features`,
    method: 'get',
  })

  return res
}

export async function getListFacilities(params: any): Promise<any> {
  const res: any = await requestV1({
    url: '/building/facilities',
    method: 'get',
    params,
  })

  return res
}

export async function getListHouseDecorations(): Promise<any> {
  const res: any = await request({
    url: '/building/house-decaration',
    method: 'get',
  })

  return res
}

export async function getListClassTypes(): Promise<any> {
  const res: any = await request({
    url: '/building/class-types',
    method: 'get',
  })

  return res
}

export async function getListHouseTypes(): Promise<any> {
  const res: any = await request({
    url: '/building/house-types',
    method: 'get',
  })

  return res
}

export async function getListParkings(): Promise<any> {
  const res: any = await request({
    url: '/building/parking',
    method: 'get',
  })

  return res
}

export async function getListHeights(): Promise<any> {
  const res: any = await request({
    url: '/building/ceiling-height',
    method: 'get',
  })

  return res
}

export async function getListNearbyPlaceTypes(): Promise<any> {
  const res: any = await request({
    url: '/building/nearby-place-type',
    method: 'get',
  })

  return res
}

export async function getListFloor(): Promise<any> {
  const res: any = await request({
    url: '/building/block-floor-count',
    method: 'get',
  })

  return res
}

export async function getListDeadlines(): Promise<any> {
  const res: any = await request({
    url: '/building/block-deadlines',
    method: 'get',
  })

  return res
}

export async function getListIpoteka(): Promise<any> {
  const res: any = await request({
    url: '/building/ipoteka',
    method: 'get',
  })

  return res
}

export async function getListInstallment(): Promise<any> {
  const res: any = await request({
    url: '/building/installment',
    method: 'get',
  })

  return res
}