/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
import settings from '@/config/settings'
import { refreshToken } from '@/features/auth/api'
import { message } from 'antd'
import type { AxiosError } from 'axios'
import axios from 'axios'
// import { refreshToken } from "@/features/auth";

function logErrors(errorObj: unknown): void {
  if (Array.isArray(errorObj)) {
    errorObj.forEach((item) => {
      logErrors(item)
    })
  } else if (typeof errorObj === 'object' && errorObj !== null) {
    Object.values(errorObj).forEach((value) => {
      logErrors(value)
    })
  } else if (typeof errorObj === 'string') {
    if (errorObj !== 'not_found' && errorObj !== 'Неправильная страница') {
      message.error(errorObj)
    }
  }
}

const request = axios.create({
  baseURL: settings.baseURL,
  timeout: settings.requestTimeout,
})

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Accept-Language'] = 'ru'
  }

  // config.validateStatus = (status) => status < 500;

  return config
}, errorHandler)

request.interceptors.response.use((response) => response.data, errorHandler)

export async function errorHandler(error: AxiosError): Promise<void> {
  if (error.response !== null) {
    // server responded with a status code that falls out of the range of 2xx
    if (error.response?.status === 401 || error.response?.status === 403) {
      const rToken = localStorage.getItem('refresh_token')
      if (rToken !== null) {
        try {
          const res = await refreshToken({ refresh: rToken })
          const { refresh, access } = res.data
          localStorage.setItem('refresh_token', refresh)
          localStorage.setItem('access_token', access)
        } catch (err) {
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('access_token')
        } finally {
          window.location.reload()
        }
      } else {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token')
      }
    } else {
      logErrors(error?.response?.data)
    }

    await Promise.reject(error.response)
  }
  if (error.request !== null) {
    // no response received from server
    await Promise.reject(error.request)
  }

  // something happened in setting up the request
  console.error(error.message)

  console.log('Error config object:', error.config)

  // Using toJSON you get an object with more information about the HTTP error
  console.log('\nError object as json:', error.toJSON())

  await Promise.reject(error)
}

export default request
