/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Flex, Table, Typography } from 'antd'
import { format } from 'date-fns'
import ClientRowHistory from './ClientRowHistory'
import FlatPlanImage from './FlatPlanImage'
import StatusGenerator from './StatusGenerator'
import TableRow from './TableRow'

function TableClients({
  clientsList,
  refetchList,
  isLoading,
  setPage,
  selectedRowIds,
  setSelectedRowIds,
  page,
}: any): JSX.Element {
  const columns = [
    {
      title: (
        <Flex>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowIds(
                  clientsList?.results.map((client: any) => client.id),
                )
              } else {
                setSelectedRowIds([])
              }
            }}
          />
        </Flex>
      ),
      width: 70,
      render: (val: any) => (
        <Flex>
          <Checkbox
            checked={selectedRowIds?.includes(val?.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowIds([...selectedRowIds, val?.id])
              } else {
                setSelectedRowIds(
                  selectedRowIds?.filter((id: any) => id !== val?.id),
                )
              }
            }}
          />
        </Flex>
      ),
    },
    {
      title: 'Клиент ID',
      width: 200,
      render: (val: any) => (
        <ClientRowHistory client={val} refetchList={refetchList} />
      ),
    },
    {
      title: 'Имя',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
          {val?.full_name}
        </Typography.Text>
      ),
    },
    {
      title: 'Телефон номер',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
          {val?.phone_number}
        </Typography.Text>
      ),
    },
    {
      title: 'Статус',
      width: 300,
      render: (val: any) => <StatusGenerator client={val} />,
    },
    {
      title: 'Дата заявки',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
          {format(new Date(val?.created_at), 'dd.MM.yyyy HH:mm')}
        </Typography.Text>
      ),
    },
    {
      title: 'Планировка',
      width: 200,
      render: (val: any) => <FlatPlanImage client={val} />,
    },
    {
      title: 'Действия',
      width: 100,
      fixed: 'right',
      render: (val: any) => (
        <TableRow key={val.id} client={val} refetchList={refetchList} />
      ),
    },
  ]

  return (
    <Flex className="mt-2">
      <Table
        columns={columns as any}
        dataSource={clientsList?.results}
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        bordered
        pagination={{
          current: page || 1,
          onChange: (pageNumber) => {
            setPage(pageNumber)
          },
          total: clientsList?.count,
          pageSize: 20,
          showSizeChanger: false,
        }}
      />
    </Flex>
  )
}

export default TableClients
