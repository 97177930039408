import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import { Button, Flex, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import HouseTableRow from './HouseTableRow'

function HouseTablePage({
  setFilterDrawerOpen,
  setAddDrawerOpen,
  data,
  refetch,
  setPage,
  filterCount,
  isLoading,
  page,
}: any): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [localStorage.getItem('theme')])

  const columns = [
    {
      title: 'Номер дома',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
          {val?.title}
        </Typography.Text>
      ),
    },
    {
      title: 'Количество этажей',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
          {val?.floor_count}
        </Typography.Text>
      ),
    },
    {
      title: 'Срок сдачи',
      width: 300,
      render: (val: any) => (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
          {val?.deadline}
        </Typography.Text>
      ),
    },
    {
      title: 'Статус',
      width: 400,
      render: (val: any) => (
        <Typography.Text
          style={{
            color: val?.status === 'active' ? '#58C27D' : '#CE5A67',
            backgroundColor: val?.status === 'active' ? '#DEF3E5' : '#F3DCDF',
          }}
          className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[5px] dark:bg-[#233332]"
        >
          • {val?.status === 'active' ? 'Активен' : 'Не активен'}
        </Typography.Text>
      ),
    },
    {
      title: 'Действия',
      width: 100,
      fixed: 'right',
      render: (val: any) => (
        <HouseTableRow key={val?.id} house={val} refetch={refetch} />
      ),
    },
  ]

  return (
    <Flex vertical>
      <Flex className="items-center justify-between">
        <Typography.Title className="m-0 !font-medium !text-2xl flex items-center gap-[12px] dark:!text-[#FFFFFF]">
          Дома комплекса ({data?.count ?? 0})
        </Typography.Title>

        <Flex gap={16}>
          <Button
            onClick={() => {
              setAddDrawerOpen(true)
            }}
            className="border-[#635BFF] h-[48px] dark:text-[#FFF] rounded-[8px] flex gap-[10px] items-center text-[#635BFF] h-auto !font-medium !text-base dark:bg-[#1A1D1F] dark:hover:!bg-[#1A1D1F]"
          >
            <PlusAddIcon />
            Добавить новый дом
          </Button>

          <Button
            className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF] dark:border-[#272B30]"
            onClick={() => {
              setFilterDrawerOpen(true)
            }}
          >
            <FiltersIcon
              style={{
                color: isDarkMode ? '#FFF' : '#1E1E1E',
                height: '14px',
                width: '14px',
              }}
            />
            Фильтр
            {filterCount > 0 && (
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                {filterCount}
              </Typography.Text>
            )}
          </Button>
        </Flex>
      </Flex>

      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Flex vertical className="w-full mt-[16px]">
          <Table
            columns={columns as any}
            dataSource={data?.results}
            scroll={{ x: 'max-content' }}
            loading={isLoading}
            bordered
            pagination={{
              current: page || 1,
              onChange: (page) => {
                setPage(page)
              },
              total: data?.count,
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HouseTablePage
