import AlertIcon from '@/components/icons/alert-icon'
import ArchiveIcon2 from '@/components/icons/archive-icon1'
import FlashIcon from '@/components/icons/flash-icon'
import WaitingIcon from '@/components/icons/waiting-icon'
import { Typography } from 'antd'
import { useMemo } from 'react'

export default function StatusGenerator({ client }: any): JSX.Element {
  const statusGenerator = useMemo((): {
    color: string
    bg: string
    icon: any
    text: string
  } => {
    if (client?.status === 1) {
      return {
        color: '#58C27D',
        bg: '#DEF3E5',
        icon: FlashIcon,
        text: 'Новый',
      }
    }
    if (client?.status === 2) {
      return {
        color: '#FA8F54',
        bg: '#FCE7DB',
        icon: WaitingIcon,
        text: 'Обработано',
      }
    }
    if (client?.status === 3) {
      return {
        color: '#CE5A67',
        bg: '#F3DCDF',
        icon: AlertIcon,
        text: 'Не обработано',
      }
    }
    if (client?.status === 4) {
      return {
        color: '#A9A9A9',
        bg: '#F5F5F5',
        icon: ArchiveIcon2,
        text: 'Архивировано',
      }
    }
    return {
      color: '#A9A9A9',
      bg: '#F5F5F5',
      icon: FlashIcon,
      text: 'Undefined',
    }
  }, [client])

  return (
    <Typography.Text
      style={{
        color: statusGenerator?.color,
        backgroundColor: statusGenerator?.bg,
      }}
      className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[5px]"
    >
      <statusGenerator.icon />
      {statusGenerator?.text}
    </Typography.Text>
  )
}
